import * as Sentry from "@sentry/nextjs";
import { useCredit } from "controllers/subscription";
import queryString from "query-string";
import toast from "react-hot-toast";
import { firebaseClient, getToken } from "../firebaseClient";
import * as analytics from "../utils/analytics";
import { setInLocalStorage } from "../utils/helpers";
import {
  deleteCredentialsIfExpired,
  getAccount,
  updateUser,
} from "../utils/sessionHelper";
import { getPlan, isAllowed } from "./subscription";
import { TWEET_BUTLER_API_BASE_URL, ez4castApiUrls } from '../utils/apiUrls';

export const formatGenerateResponse = async (data, session) => {
  let tweets: any = [];
  let array = data.tweetsRich
    ? data.tweetsRich
    : data.tweets.map((tw) => {
        return { text: tw };
      });
  array.forEach((tw) => {
    if (tw != "empty") {
      let fullTweet = {
        ...tw,
        full_text: tw.text,
        user: {
          profile_image_url_https: getAccount(session).image,
          name: getAccount(session).name,
          screen_name: getAccount(session).twUserName,
        },
      };
      tweets.push(fullTweet);
    }
  });
  data.originals.forEach((tw) => {
    if (tw?.created_at?._seconds)
      tw.created_at = new Date(tw.created_at._seconds * 1000);
  });
  data.tweets = tweets;
};

export const search = async (
  session,
  topic,
  params = undefined as any,
  mode = "search",
  conf = {} as any,
  mainContext = undefined,
) => {
  let userData = getAccount(session);
  let url = "";
  let isFromServer = typeof window == "undefined";
  console.log("isFromServer:", isFromServer);

  if (!topic) {
    toast.error(
      "You have not assigned search keywords to your profile. Please go to your Account Settings and configure it under AI settings",
      { style: { background: "gray.600", color: "#222" } },
    );
    return;
  }

  if (mode != "server" && !isFromServer)
    setInLocalStorage("searchTopic_" + mode, topic);

  if (
    (!userData?.subscription?.isSubscribed ||
      (conf.feature && !isAllowed(session.user, conf.feature))) &&
    userData?.remainingFreeSearch &&
    userData.remainingFreeSearch > 0
  ) {
    consumeFreeSearch(session);
  } else {
    if (!userData) console.log("Error: session not initialized");
  }

  try {
    let properties = { mode: "", topic: "" };
    if (params) {
      properties = {
        ...params,
      };
    }
    properties.mode = mode;
    properties.topic = topic;
    analytics.log("search", properties);
    let randomize = true;

    if (
      (!userData?.subscription?.isSubscribed &&
        !userData?.remainingFreeSearch) ||
      (userData?.disableRandom && window?.location?.href?.includes("dev"))
    ) {
      randomize = false;
    }

    let call = "tweets";
    if (mode == "generate") call = "searchAndGenerate";
    else if (mode == "searchAndGenerateVariations")
      call = "searchAndGenerateVariations";
    else if (mode == "generateFromList") call = "generateFromList";

    params.useBestAi = await useCredit(
      session,
      mainContext,
      "creditsBestai",
      false,
    );
    params.fineTunedModel = getAccount(session)?.fineTunedModel || "";

    url = `${TWEET_BUTLER_API_BASE_URL}/${call}?topic=${encodeURIComponent(
      topic,
    )}&count=40&randomize=${randomize}&description=${encodeURIComponent(
      userData?.description,
    )}`;

    if (mode == "generateFromList") url += `&userId=${session?.user?.uid}`;
    else url += `&userId=${userData?.idAccount}`;

    if (params && !isFromServer) {
      addBasePropertiesToParams(params, session);
      url += "&" + new URLSearchParams(params).toString();
    }

    let response;
    const token = await getToken(session, "search-" + call);
    if (isFromServer) {
      response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          tokenuserid: session.user.uid,
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          tokenuserid: session.user.uid,
          Authorization: `Bearer ${token}`,
        },
      });
    }

    let data = await response.json();

    if (data.success === 0) {
      toast.error(data.error, {
        style: { background: "gray.600", color: "#222" },
      });
    }

    return data;
  } catch (e) {
    console.log(
      "Error in fetch getTweets: " + e.message,
      JSON.stringify({ e, params, url }),
    );
    toast.error("An error occurred, please try again", {
      style: { background: "gray.600", color: "#222" },
    });
    Sentry.captureException(e);
  }
};

export const searchFromServer = async (topic, params = undefined) => {
  try {
    let randomize = true;

    let call = "tweets";

    let url = `${TWEET_BUTLER_API_BASE_URL}/${call}?topic=${encodeURIComponent(
      topic,
    )}&count=40&randomize=${randomize}`;

    if (params) {
      url += "&" + new URLSearchParams(params).toString();
      url += "&key=" + process.env.TWEETBUTLER_KEY;
    }

    console.log("url: " + url);
    let response = await fetch(url, {
      method: "GET",
      headers: {
        "X-CALL-ORIGIN": "server",
      },
    });
    let data = await response.json();

    return data;
  } catch (e) {
    console.log("Error in searchFromServer: " + e.message, e);
    Sentry.captureException(e);
  }
};

export const generatePost = async (tweet, session) => {
  console.log("generatePost for: " + tweet.full_text);

  if (!tweet) {
    toast.error("Please enter a valid tweet", {
      style: { background: "gray.600", color: "#222" },
    });
    return;
  }

  try {
    let properties = { tweet: tweet };
    analytics.log("generatePost", properties);

    let url = `${TWEET_BUTLER_API_BASE_URL}/generatePost?text=${encodeURIComponent(
      tweet.full_text,
    )}`;
    const token = await getToken(session, "generatePost");
    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        tokenuserid: session.user.uid,
        Authorization: `Bearer ${token}`,
      },
    });
    let data = await response.json();

    if (data.success === 0) {
      toast.error(data.error, {
        style: { background: "gray.600", color: "#222" },
      });
    }

    return data;
  } catch (e) {
    console.log("Error in generatePost: " + e.message);
    toast.error("An error occurred, please try again", {
      style: { background: "gray.600", color: "#222" },
    });
    Sentry.captureException(e);
  }
};

export const grow = async (
  session,
  topic,
  params = undefined as any,
  onOpenUpgrade = undefined,
  type = "usual",
) => {
  try {
    if (type === "usual") {
      setInLocalStorage("searchTopic_grow", topic);
    }
    let userData = getAccount(session);

    if (!isAllowed(session.user, "engage")) {
      if (userData?.remainingFreeSearch && userData.remainingFreeSearch > 0) {
        consumeFreeSearch(session);
      } else {
        console.log("not allowed");
        //@ts-ignore
        onOpenUpgrade && onOpenUpgrade();
        return;
      }
    }

    analytics.log("grow", { topic: topic });
    let response;
    if (type === "mentions") {
      const mentionUrl = ez4castApiUrls.mentions.prod;
      const token = await getToken(session, "grow");
      response = await fetch(mentionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          tokenuserid: session?.user?.uid ?? "",
        },
        body: JSON.stringify(params),
      });
    } else {
      let url = `${TWEET_BUTLER_API_BASE_URL}/grow?topic=${encodeURIComponent(
        topic,
      )}&idAccount=${userData.idAccount}&userId=${
        userData.id
      }&description=${encodeURIComponent(userData?.description)}`;

      if (getPlan(session?.user)?.includes("enterprise")) {
        params.generateReplies = true;
        params.model = "j2-jumbo";
      }

      if (params && !params.count) params.count = 200;

      if (params) {
        url += "&" + new URLSearchParams(params).toString();
      }

      response = await fetch(url);
    }

    let data = await response.json();

    if (data.success === 0) {
      toast.error(data.error);
      deleteCredentialsIfExpired(session, data.code);
    }

    return data;
  } catch (e) {
    console.log("Error in fetch grow: " + e.message);
    toast.error("An error occurred, please try again", {
      style: { background: "gray.600", color: "#222" },
    });
    Sentry.captureException(e);
  }
};

export const getHooks = async (session, params, mainContext) => {
  setInLocalStorage("searchTopic_hooks", params.topic);

  try {
    let userData = getAccount(session);

    params.useBestAi = await useCredit(
      session,
      mainContext,
      "creditsBestai",
      false,
    );
    params.fineTunedModel = getAccount(session)?.fineTunedModel || "";

    if (!isAllowed(session.user, "hook")) {
      if (userData?.remainingFreeSearch && userData.remainingFreeSearch > 0) {
        consumeFreeSearch(session);
      } else {
        console.log("not allowed");
        //@ts-ignore
        mainContext.onOpenUpgrade && mainContext.onOpenUpgrade();
        return;
      }
    }

    analytics.log("get_hooks", { topic: params.topic });
    let url = `${TWEET_BUTLER_API_BASE_URL}/hooks`;

    if (params) url += "?" + new URLSearchParams(params).toString();

    if (session?.user?.data?.creditsAuto > 0) {
      url += "&model=" + "j1-jumbo";
    }

    const token = await getToken(session, "getHooks");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        tokenuserid: session.user.uid,
      },
    });
    let data = await response.json();

    if (data.success === 0) {
      toast.error(data.error);
    }

    return data;
  } catch (e) {
    console.log("Error in fetch hooks: " + e.message);
    toast.error("An error occurred, please try again");
    Sentry.captureException(e);
  }
};

export const getIdeas = async (session, params, onOpenUpgrade = undefined) => {
  setInLocalStorage("searchTopic_ideas", params.topic);

  try {
    let userData = getAccount(session);

    if (!isAllowed(session.user, "idea")) {
      if (userData?.remainingFreeSearch && userData.remainingFreeSearch > 0) {
        consumeFreeSearch(session);
      } else {
        console.log("not allowed");
        //@ts-ignore
        onOpenUpgrade && onOpenUpgrade();
        return;
      }
    }

    analytics.log("get_ideas", { topic: params.topic });
    let url = `${TWEET_BUTLER_API_BASE_URL}/generateIdeas`;

    if (params) url += "?" + new URLSearchParams(params).toString();

    const token = await getToken(session, "getHooks");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        tokenuserid: session.user.uid,
      },
    });
    let data = await response.json();

    if (data.success === 0) {
      toast.error(data.error);
    }

    return data;
  } catch (e) {
    console.log("Error in fetch hooks: " + e.message);
    toast.error("An error occurred, please try again");
    Sentry.captureException(e);
  }
};

export const continuePost = async (session, params, mainContext) => {
  try {
    params.useBestAi = await useCredit(
      session,
      mainContext,
      "creditsBestai",
      false,
    );
    params.fineTunedModel = getAccount(session)?.fineTunedModel || "";

    analytics.log("get_generateNext", { topic: params.topic });
    let url = `${TWEET_BUTLER_API_BASE_URL}/generateNext`;

    const token = await getToken(session, "continuePost");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        tokenuserid: session.user.uid,
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    if (data.success === 0) {
      toast.error(data.error);
    }

    return data;
  } catch (e) {
    console.log("Error in fetch hooks: " + e.message);
    toast.error("An error occurred, please try again");
    Sentry.captureException(e);
  }
};

export const warm = async (session) => {
  try {
    let user = session.user;
    let idAccount = getAccount(session)?.idAccount;
    let url = `${TWEET_BUTLER_API_BASE_URL}/warm?id=${idAccount}`;
    const token = await getToken(session, "warm");
    let res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        tokenuserid: user.uid,
        Authorization: `Bearer ${token}`,
      },
    });

    let data = await res.json();
    let dataToSave: any = {};

    if (data.description && user.uid) {
      if (data.description) {
        dataToSave.description = data.description;
        user.data.description = data.description;
      }
      if (data.who) {
        dataToSave.who = data.who;
        user.data.who = data.who;
      }
      if (data.topics) {
        dataToSave.topics = data.topics;
        user.data.topics = data.topics;
      }
    } else if (data.bio && user.uid) {
      //@ts-ignore
      dataToSave.description = data.bio;
      //@ts-ignore
      dataToSave.who = data.bio;
      //@ts-ignore
      dataToSave.topics = "";
    }
    await updateUser(session, dataToSave);

    return data;
  } catch (e) {
    console.log("Error in warm: " + e.message, e);
    Sentry.captureException(e);
    return null;
  }
};

export const getKeywords = async (session) => {
  try {
    console.log("getKeywords");
    let user = session?.user;

    let url;
    if (user?.data?.description)
      url = `${TWEET_BUTLER_API_BASE_URL}/keywords?text=${encodeURIComponent(
        getAccount(session).description,
      )}`;
    else
      url = `${TWEET_BUTLER_API_BASE_URL}/keywords?id=${
        getAccount(session).idAccount
      }`;

    const token = await getToken(session, "getKeywords");
    let res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        tokenuserid: user.uid,
        Authorization: `Bearer ${token}`,
      },
    });

    let data = await res.json();

    if (data.success) {
      console.log("Got keywords: " + data.keywords);
      let dataToSave = {
        keywords: data.keywords,
      };

      await updateUser(session, dataToSave, false, true);

      return data.keywords;
    }

    return null;
  } catch (e) {
    console.log("Error in warm: " + e.message, e);
    Sentry.captureException(e);
    return null;
  }
};

export const getHandleSuggestions = async (session, params, signal?: any) => {
  try {
    let url = `${TWEET_BUTLER_API_BASE_URL}/autocomplete?`;

    if (params) {
      addBasePropertiesToParams(params, session);
      url += "&" + new URLSearchParams(params).toString();
    }

    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      signal,
    });

    let data = await response.json();
    return data;
  } catch (e) {
    console.log("Error in fetching username suggestions: ", e.message);
    return null;
  }
};

export const getNMostRecentTweets = async (session, params) => {
  try {
    let url = ez4castApiUrls.mostRecentTweets.prod;
    let user = session?.user;
    const token = await getToken(session, "getNMostRecentTweets");
    if (params) {
      url += "?" + new URLSearchParams(params).toString();
    }

    let response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        tokenuserid: user.uid,
        Authorization: `Bearer ${token}`,
      },
    });

    let data = await response.json();
    if (data.success === 0) {
      toast.error(data.error, {
        style: { background: "gray.600", color: "#222" },
      });
    }

    return data;
  } catch (e) {
    console.log("Error in fetching most recent tweets: ", e.message);
    Sentry.captureException(e);
    return null;
  }
};

export const getTweet = async (session, tweetId: string, signal?: any) => {
  try {
    const payload: any = {
      twClientId: getAccount(session)?.thCustomClientId,
      twClientSecret: getAccount(session)?.thCustomClientSecret,
      twAccessToken: getAccount(session)?.thWriteAccessToken,
      twSecretToken: getAccount(session)?.thWriteSecretToken,
      app: getAccount(session)?.thApp,
      idTweet: tweetId,
    };

    const url = queryString.stringifyUrl({
      url: ez4castApiUrls.fetchTweet.prod,
      query: payload,
    });

    const res = await fetch(url, { signal });
    const data = await res.json();
    return data;
  } catch (err) {
    console.log("Error in fetching tweet: ", err);
    Sentry.captureException(err);
    return null;
  }
};

async function consumeFreeSearch(session) {
  session.user.data.remainingFreeSearch--;
  const db = firebaseClient.firestore();
  await db
    .collection("users")
    .doc(session.user.uid)
    .update({
      remainingFreeSearch: firebaseClient.firestore.FieldValue.increment(-1),
    })
    .catch((e) => {
      console.log("e: " + e.message);
    });
}

function addBasePropertiesToParams(params, session) {
  if (params) {
    if (getAccount(session)?.thWriteAccessToken) {
      params.twClientId = getAccount(session)?.thCustomClientId;
      params.twClientSecret = getAccount(session)?.thCustomClientSecret;
      params.twAccessToken = getAccount(session)?.thWriteAccessToken;
      params.twSecretToken = getAccount(session)?.thWriteSecretToken;
      params.thApp = getAccount(session)?.thApp ?? "";
      params.app = getAccount(session)?.thApp ?? "";
      params.tokenType = "write";
    } else if (getAccount(session)?.thReadAccessToken) {
      params.twAccessToken = getAccount(session)?.thReadAccessToken;
      params.twSecretToken = getAccount(session)?.thReadSecretToken;
      params.app = getAccount(session)?.thReadApp ?? "T_TWEETHUNTER";
      params.tokenType = "read";
    }
  }
}
