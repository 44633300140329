// @ts-nocheck
import React, { useState, useRef, useMemo, useContext, useEffect } from "react";
import {
  Button,
  Collapse,
  Box,
  Textarea,
  Text,
  Spinner,
  IconButton,
  Icon,
  VStack,
  useColorModeValue,
  theme,
  useMediaQuery,
  Tooltip,
  Input,
  Flex,
  Divider,
  CloseButton,
  Center,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  Grid,
  GridItem,
  AlertDescription,
  Alert,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  HStack,
  Select,
  TabPanels,
  Tabs,
  TabList,
  TabPanel,
  Tab,
  useColorMode,
} from "@chakra-ui/react";
import { ChevronDownIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import moment from "moment";
import classNames from "classnames";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineDocumentAdd, HiOutlineLightningBolt } from "react-icons/hi";
import { BsCheck } from "react-icons/bs";
import { FaRobot } from "react-icons/fa";
import { GrUndo, GrRedo } from "react-icons/gr";
import { TiTickOutline } from "react-icons/ti";
import shortid from "shortid";
import useUndoableState from "../../hooks/UndoRedoHook.tsx";
import { ThreadTweetPreviewSidebar } from "./ThreadTweetPreviewSidebar";
import { firebaseClient } from "../../firebaseClient";
import { predict } from "controllers/popularity";
import { useSession } from "next-auth/client";
import twitter from "twitter-text";
import { BiCopy } from "react-icons/bi";
import {
  MdSuperscript,
  MdSubscript,
  MdFormatBold,
  MdFormatItalic,
  MdStrikethroughS,
  MdCode,
  MdFormatUnderlined,
} from "react-icons/md";
import { CgFormatItalic } from "react-icons/cg";
import { motion } from "framer-motion";
import {
  replaceSelectedWithUnicode,
  keepRefreshSelection,
} from "./text-to-unicode";
import { BsFillImageFill } from "react-icons/bs";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import "emoji-mart/css/emoji-mart.css";
import data from "emoji-mart/data/twitter.json";
import { NimblePicker } from "emoji-mart";
import * as Sentry from "@sentry/nextjs";
import autosize from "autosize";
import { TweetContext } from "../../context/tweetContext";
import { MainContext } from "../../context/mainContext";
import { continuePost } from "../../controllers/search";
import { generate } from "../../controllers/variations";
import { saveTweet } from "../../controllers/schedule";
import toast from "react-hot-toast";
import {
  getAccount,
  getAccountIsPremium,
  updateUser,
} from "../../utils/sessionHelper";
import {
  extractImages,
  cleanTweetForCountChar,
  hexToHexa,
  hslToHsla,
} from "../../utils/helpers";
import { ImageThumbnail } from "./image-thumbnail";
import { Suggestions } from "components/suggestions";
import { useSuggestions } from "components/suggestions/useSuggestions";
import { useDebouncedCallback } from "use-debounce";
import { getImageSize, getVideoInfo } from "../../utils/uploadUtils";
import { useFirebaseUser } from "../../utils/useFirebaseUser";
import { useOnClickOutside } from "../../utils/helpers";
import { useDropzone } from "react-dropzone";
import * as Bowser from "bowser";
import { AiOutlineGif } from "react-icons/ai";
import { GifBox } from "components/gif-box";
import StringSimilarity from "string-similarity";
import { isMobile } from "react-device-detect";
import { uploadOnS3 } from "utils/uploadUtils";
import { searchUsers } from "controllers/miscellaneous";
import { ImageEditPopup } from "components/popups/imageEditPopup";
import { useOnboardingSteps } from "components/onboard-panel/useOnboardingSteps";
import { hideTweet } from "controllers/bookmark";
import { Snippets } from "components/snippets";
import { AiButtonSVGIcon } from "./icons";
import { prompts } from "./utils";
import { useChatContext } from "context/chatContext";
import { layerStyle, radius, textStyle, variant } from "theme/names";
import { border } from "theme/foundations/border";
import { InfoIconSVG } from "components/icons/InfoIconSVG";
import { colors } from "theme/colors/colors";
import { SaveIcon } from "components/icons/SaveIcon";
import { PlusIcon } from "components/icons/PlusIcon";
import { EditIconSVG } from "components/icons/EditIconSVG";
import { EditIconComponent } from "components/icons/EditIconComponent";
import { color as colorNames } from "theme/names";
import { Bolt } from "components/icons/Bolt";
import { FastForward } from "components/icons/FastForward";
import { AIReplyButtonsMemoed } from "./ai-reply-buttons";
import { useWindowWidth } from "@react-hook/window-size";
import GenerateImage from "./generate-image";
import { createThumbnail, getUserMedias, postMedia } from "controllers/media";
import MediaMasonry, { MediaObject } from "components/media-masonry";
import Loader from "react-spinners/PuffLoader";
import { isAllowed } from "controllers/subscription";
import { sanitizeInput } from "utils/sanitizationHelper";
import { FileUploader } from "./file-uploader";

const BUCKET_NAME = process.env.NEXT_PUBLIC_PROJECT_BUCKET;

const baseStyle = {};

const activeStyle = {
  borderColor: "#2196f3",
  borderWidth: 2,
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

let browser;
if (typeof window !== "undefined")
  browser = Bowser.getParser(window.navigator.userAgent);

const isFileValid = async (file) => {
  if (!file.type.includes("image") && !file.type.includes("video")) {
    toast.error("Add image and video only");
    return false;
  }

  if (
    file.type.includes("image") &&
    !["image/png", "image/jpeg", "image/gif"].includes(file.type)
  ) {
    toast.error("Unsupported image format");
    return false;
  }

  if (["image/png", "image/jpeg"].includes(file.type) && file.size > 5000000) {
    toast.error("Images cannot be bigger than 5mb");
    return false;
  } else if (["video/webm"].includes(file.type)) {
    toast.error("Video format not supported");
    return false;
  } else if (["image/gif"].includes(file.type) && file.size > 15000000) {
    toast.error("Gifs cannot be bigger than 15mb");
    return false;
  } else if (file.size > 128000000) {
    toast.error("Files cannot be bigger than 128mb");
    return false;
  }

  if (!file.type.includes("video")) {
    let size = await getImageSize(file);
    if (
      size.height < 4 ||
      size.height > 8192 ||
      size.width < 4 ||
      size.width > 8192
    ) {
      toast.error("Image dimensions must be >= 4x4 and <= 8192x8192");
      return false;
    }
  } else if (file.type.includes("video")) {
    let videoData = await getVideoInfo(file);
    if (videoData?.duration >= 140) {
      toast.error("Video can't exceed 140 seconds in duration");
      return false;
    }
    if (videoData?.bitrate >= 60000000) {
      toast.error(
        "The video bitrate is too high and is not supported by Twitter"
      );
      return false;
    }
  }

  return true;
};
export const TweetComposer = React.forwardRef((props: any, ref) => {
  let {
    id,
    setId,
    createDraft,
    newTweet,
    initText,
    initialTextStates,
    initFiles,
    initThreadTweets,
    disableHeader = false,
    isFullMode = false,
    submit = null,
    onUpdate = null,
    disableImage = false,
    isDisabled = false,
    disableThread = false,
    disableSnippets = false,
    disableRichText = false,
    customSave = undefined,
    mode = "tweet",
    isPostTwitter = true,
    disableReWrite = false,
    disableAiContinue = false,
    isFocused = false,
    removeTweet = undefined,
    likeAndRemove = undefined,
    tweet = undefined,
    disableGif = false,
    selectedTweet = undefined,
    disablePreview = false,
    disableSuggestion = false,
    imageEditor = false,
    placeHolder = `Write here. \n\n\nSkip 3 lines to start a thread.`,
    nbCharMax = 280,
    onNewDraft = () => { },
  } = props;
  const initialTextState = {
    id: id,
    text: initText ? initText : "",
    typingTimeout: undefined,
    isSaved: false,
    scheduledTime: undefined,
    files: initFiles ? initFiles : [],
    threadTweets: initThreadTweets ? initThreadTweets : [],
  };

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSaved, setIsSaved] = React.useState(false);
  const [nbChar, setNbChar] = React.useState(0);
  const [tweetError, setTweetError] = React.useState({});
  const {
    state: textState,
    states: textStates,
    setState: setTextState,
    resetState: resetTextState,
    index: textStateIndex,
    lastIndex: textStateLastIndex,
    goBack: undoTextState,
    goForward: redoTextState,
  } = useUndoableState({
    initState: initialTextState,
    initStates: initialTextStates,
  });

  const canUndo = textStateIndex > 0;
  const canRedo = textStateIndex < textStateLastIndex;

  const [session, loading] = useSession();
  const isMountedRef = React.useRef(false);

  const screenWidth = useWindowWidth();
  const isTooSmallForThread = screenWidth < 800;

  const tweetContext: any = React.useContext(TweetContext);
  const chatContext: any = useChatContext();
  const mainContext = useContext(MainContext);
  const isAuthenticated = useFirebaseUser();

  const [isSnippetsOpen, setIsSnippetsOpen] = React.useState(false);
  const [isRichTextOpen, setIsRichTextOpen] = React.useState(false);

  const [isGenerating, setIsGenerating] = React.useState(false);
  const [isReWriting, setIsReWriting] = React.useState(false);
  const [isSavingImage, setIsSavingImage] = React.useState(false);
  const refTriggerEmoji = React.useRef();
  const refTextArea = React.useRef(null);
  const refData = React.useRef();
  const refId = React.useRef();
  const {
    isSuggestionsOpen,
    setIsSuggestionOpen,
    checkForSuggestions,
    suggestions,
    setSuggestions,
    updateText,
    disableArrowKeys,
    position,
  } = useSuggestions(refTextArea);
  refId.current = id;

  const refPond = React.useRef();
  const backgroundColor = useColorModeValue("white", "#1E1E1E");
  const aiOptionPopoverBg = useColorModeValue("white", "#1E1E1E");
  const aiOptionHeaderColor = useColorModeValue("#0A66C2", "linkedin.300");
  const aiOptionActionButtonHoverBg = useColorModeValue("#f6f6f6", "#252525");
  const { colorMode } = useColorMode();
  const debounced = useDebouncedCallback(checkForSuggestions, 400);

  const [alwaysShowSidebar, setAlwaysShowSidebar] = useState<boolean>(false);
  const [hasInit, setHasInit] = useState<boolean>(false);
  const [needReset, setNeedReset] = useState<boolean>(false);

  const [popularity, setPopularity] = useState({
    prediction: 0,
    proba_outperforming: 0,
    confidence: 0.5,
  });
  const [isLoadingPop, setIsLoadingPop] = useState<boolean>(false);
  const [isShowPrediction, setIsShowPrediction] = useState<boolean>(false);
  const [alwaysShowPrediction, setAlwaysShowPrediction] =
    useState<boolean>(false);
  const [accountForPrediction, setAccountForPrediction] = useState("");
  const [isLoadingAccountPrediction, setIsLoadingAccountPrediction] =
    useState<boolean>(false);
  const [previousPopularityText, setPreviousPopularityText] =
    useState<string>("");

  const [isOpenEmoji, setIsOpenEmoji] = React.useState(false);
  const [isGifOpen, setIsGifOpen] = useState<boolean>(false);
  const [isSavingGif, setIsSavingGif] = useState<boolean>(false);
  const [selectedImageId, setSelectedImageId] = useState<string>("");
  const [medias, setMedias] = useState<MediaObject[]>([]);
  const [hasFetchedMedia, setHasFetchedMedia] = useState<boolean>(false);
  const [selectedMedias, setSelectedMedias] = useState<string[]>([]);
  const [isPremium, setIsPremium] = useState(false);
  const [isShowThreadTweetPreview, setIsShowThreadTweetPreview] =
    useState(false);
  const [postCharMax, setPostCharMax] = useState(nbCharMax);
  const [tabIndex, setTabIndex] = useState(0);
  const refEmoji = React.useRef<any>();
  const suggestionPopupRef = useRef(null);
  const refGif = useRef(null);

  const gifContainerBorder = useColorModeValue("#ccd0d5", "whiteAlpha.400");
  const gifContainerBg = useColorModeValue("white", "#1E1E1E");
  const borderColor = useColorModeValue("gray.100", "whiteAlpha.400");
  const nimblePickerTheme = useColorModeValue("light", "dark");
  const engageContainerBg = useColorModeValue("white", "#3b3b3b");

  const {
    isOpen: isOpenRewrite,
    onOpen: onOpenRewrite,
    onClose: onCloseRewrite,
  } = useDisclosure();
  const {
    isOpen: isOpenImageEdit,
    onOpen: onOpenImageEdit,
    onClose: onCloseImageEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenImageUpload,
    onOpen: onOpenImageUpload,
    onClose: onCloseImageUpload,
  } = useDisclosure();
  const { updateOnboardSteps } = useOnboardingSteps();

  const onFileUpload = async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setIsSavingImage(true);
      let file = acceptedFiles[0];
      if (!(await isFileValid(file))) {
        setIsSavingImage(false);
        setIsSavingGif(false);
        return;
      }

      let isGif = file.type.includes("gif");
      let isVideo = file.type.includes("video");
      let id = "";

      if (isVideo) id += "vid-";

      if (isGif) {
        id += "gif-";
      }
      id += shortid.generate().replace("-", "_");
      const params = {
        Bucket: BUCKET_NAME,
        Key: "userUpload/" + id,
        Body: file,
        ACL: "public-read",
      };
      try {
        await uploadOnS3(params);
        addFileOnThread(id);
        onCloseImageUpload();
      } catch (error) {
        toast.error("Error while uploading file");
      }
      setIsSavingImage(false);
      setIsSavingGif(false);
    }
  };
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/*,video/*",
    onDropRejected: () => toast.error("Add image and video only"),
    onDrop: onFileUpload,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const fetchData = async () => {
    const response = await getUserMedias(session);
    if (response?.success) {
      setMedias(response?.data);
      setHasFetchedMedia(true);
    }
  };

  useOnClickOutside(refEmoji, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setIsOpenEmoji(false);
  });
  useOnClickOutside(suggestionPopupRef, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setIsSuggestionOpen(false);
    if (refTextArea?.current?.focus) {
      refTextArea.current.focus();
    }
  });
  useOnClickOutside(refGif, async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    setIsGifOpen(false);
  });

  const focus = async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    refTextArea?.current?.focus();
  };

  React.useEffect(() => {
    if (!hasInit && isAuthenticated) {
      setHasInit(true);
      setAlwaysShowSidebar(getAccount(session)?.alwaysShowSidebar ?? false);
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    if (isFocused) {
      focus();
    }
  }, [isFocused]);

  React.useEffect(() => {
    autosize(refTextArea.current);
    return () => {
      autosize.destroy(refTextArea.current);
    };
  }, []);

  const forceUpdateAutosize = function () {
    setTimeout(() => {
      autosize.update(refTextArea.current);
    }, 100);
  };

  React.useEffect(() => {
    forceUpdateAutosize();
  }, [
    tweetContext.isOpen,
    tweetContext.isEditAndTweet,
    isGenerating,
    tweetContext.isTweetTextChanged,
  ]);

  React.useImperativeHandle(ref, () => ({
    textState: () => {
      return textState;
    },
    pushText,
    pushOnThread,
    editThread,
    setTextState: (state) => {
      updateState(state);
    },
    textStates: () => {
      return textStates;
    },
    editText: (text) => {
      console.log("useImperativeHandle");
      editText(text);
    },
    focus: focus,
    resetUploader: (newFiles) => { },
    simulateInput: () => {
      var event = new Event("input", { bubbles: true });
      var node = document.getElementById("tweet-area");
      node.dispatchEvent(event);
    },
  }));

  React.useEffect(() => {
    if (mode == "taplio-push") {
      let text = textState?.text;
      setNbChar(text.trimEnd()?.length);
    } else if (textState?.text?.length) {
      let text = textState?.text;
      if (!textState?.threadTweets || textState?.threadTweets?.length <= 1) {
        text = cleanTweetForCountChar(text);
      }
      setNbChar(twitter.parseTweet(text).weightedLength);
    } else setNbChar(0);

  }, [textState]);

  React.useEffect(() => {
    if (mode == "tweet" && tweetContext?.textState?.text !== undefined) {
      updateState(tweetContext.textState);
    }
  }, [tweetContext.textState]);

  React.useEffect(() => {
    textState.text = initText;
    textState.files = initFiles;
    updateState(textState);
    forceUpdateAutosize();
  }, [initText, initFiles]);

  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  React.useEffect(() => {
    if (isOpenImageUpload) return;
    setMedias([]);
    setHasFetchedMedia(false);
  }, [isOpenImageUpload]);

  React.useEffect(() => {
    console.log("tweet composer force refresh - id: " + id);

    setPopularity({ prediction: 0, proba_outperforming: 0, confidence: 0.5 });
    setTweetError({});
  }, [id, tweetContext.refresh]);

  useEffect(() => {
    if (Boolean(session) === true && isAuthenticated) {
      const userIsPremium = getAccountIsPremium(session)?.isPremium;
      setIsPremium(userIsPremium);
      setPostCharMax(userIsPremium ? 25000 : 280);
    }
  }, [session, isAuthenticated]);

  const handleKeyDown = (event) => {
    disableArrowKeys(event);
    if (
      event.key === "Enter" &&
      event.shiftKey &&
      (event.ctrlKey || event.metaKey)
    ) {
      console.log("likeAndRemove");
      likeAndRemove && likeAndRemove();
    } else if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
      console.log("submit");
      submit && submit();
    } else if (event.key === "i" && (event.ctrlKey || event.metaKey)) {
      console.log("hide");
      if (session?.user?.uid && tweet?.id_str)
        hideTweet(tweet, session?.user?.uid, -1);
      removeTweet && removeTweet(tweet);
    } else if (event.key === "e" && (event.ctrlKey || event.metaKey)) {
      console.log("show emoji");
      setIsOpenEmoji(true);
    }
  };

  const updateState = (newState) => {    let newObject = {
      ...newState,
      threadTweets: newState?.text?.split("\n\n\n") ?? [],
    };

    setTextState(newObject);
    refData.current = newState;
  };

  const pushText = async (text) => {
    textState.text = (textState.text ?? "") + text;
    editText(textState.text);
    tweetContext.setIsTweetTextChanged(true);
  };
  const pushOnThread = async (text, idx) => {
    const contextTextState = tweetContext?.refComposer?.current?.textState();
    let textState =
      mode !== "autoPlug"
        ? contextTextState
          ? contextTextState
          : textState
        : {
          ...initialTextState,
          ...refData.current,
          threadTweets: [refData?.current?.text],
        };

    if (textState?.threadTweets?.[idx] === undefined) {
      textState.threadTweets[idx] = "";
    }
    textState.threadTweets[idx] += " " + text;
    textState.text = textState.threadTweets.join("\n\n\n");
    editText(textState.text);
    tweetContext.setIsTweetTextChanged(true);
  };

  const editThread = async (text, idx) => {
    textState.threadTweets[idx] = text;
    textState.text = textState.threadTweets.join("\n\n\n");
    editText(textState.text);
    tweetContext.setIsTweetTextChanged(true);
  };

  const editText = async (value: string, updatedFiles = null) => {
    value = sanitizeInput(value);
    setIsSaving(true);
    if (refData.current.typingTimeout)
      clearTimeout(refData.current.typingTimeout);
    if (refData.current.predictTimeout)
      clearTimeout(refData.current.predictTimeout);

    let newState = {
      ...refData.current,
      id: refId.current,
    };

    if (value || value === "") {
      let tweets = value.split("\n\n\n");
      newState.threadTweets = tweets;
      newState.text = value;

      let images = extractImages(value);
      newState.files = images;
      if (selectedMedias !== images) {
        setSelectedMedias(images);
      }
    }

    if (createDraft || customSave) {
      newState.typingTimeout = setTimeout(async () => {
        if (isMountedRef.current) {
          checkError(value);
          const dataToSave: any = { text: value };

          let hasSaved = await saveTweet(
            session,
            refId.current,
            dataToSave,
            createDraft,
            customSave
          );
          if (hasSaved) setIsSaving(false);
        }
      }, 400);
    }

    updateState(newState);
    onUpdate && onUpdate(newState);
  };

  const addFileOnThread = async (fileName) => {
    if (refTextArea?.current?.focus) refTextArea.current.focus();
    
    if (mode === "autoDm") {
      const currentText = textState?.text || "";
      const newText = currentText + (currentText ? " " : "") + `[img:${fileName}]`;
      editText(newText);
      return;
    }

    if (refTextArea?.current?.selectionStart) {
      const cursorPos = refTextArea.current.selectionStart;
      const textBeforeCursor = refTextArea.current.value.substring(
        0,
        cursorPos
      );
      const threadIndex = textBeforeCursor.split("\n\n\n").length - 1;
      if (threadIndex > 0) {
        pushOnThread(`[img:${fileName}]`, threadIndex);
      } else {
        pushOnThread(`[img:${fileName}]`, 0);
      }
    } else {
      pushOnThread(`[img:${fileName}]`, 0);
    }
  };

  const checkError = (value: string) => {
    let newError = {};

    if (!value?.match) return;

    let countMention = (value.match(/@/g) || [])?.length;
    if (countMention >= 5) {
      newError = {
        text: "Keep your account safer by not performing unsolicited mentions in bulk. This can be associated to spam and may be flagged by Twitter.",
        type: "error",
      };
    } else if (value[0] == "@") {
      newError = {
        text: "Starting your tweet with a mention drastically reduces its reach.",
        type: "warning",
      };
    }

    setTweetError(newError);
  };

  const handleSelect = (handle: string) => {
    updateText({ currentText: textState?.text, handle }, (updatedText) => {
      editText(updatedText);
    });
  };

  const getSimilarityWarning = () => {
    if (
      !selectedTweet?.full_text ||
      tweetContext?.selectedTweet?.isVariation ||
      !textState?.text ||
      selectedTweet?.user?.id_str == getAccount(session)?.idAccount ||
      selectedTweet?.user?.screen_name == getAccount(session)?.twUserName
    )
      return;

    let similarity = StringSimilarity.compareTwoStrings(
      selectedTweet?.full_text,
      textState?.text
    );
    let message = "";
    let color = "gray";

    let toolTipMessage =
      "Your tweet is too similar to the original tweet. Please give it your unique touch by changing or adding more content.";
    let status = "";

    if (similarity > 0.99) {
      message = "⚠️ Too similar";
      color = "red.500";
      status = "error";
    } else if (similarity > 0.9) {
      message = "Keep going";
      color = "orange.600";
      status = "warning";
    } else if (similarity > 0.85) {
      message = "Almost there";
      color = "orange.400";
      status = "warning";
    } else {
      message = "All good";
      toolTipMessage =
        "This tweet is different from the original, you're good to go.";
      color = "green.400";
      status = "success";
    }

    return (
      <Flex justifyContent="flex-end">
        <Tooltip label={toolTipMessage}>
          <Alert status={status}>
            <AlertDescription>{message}</AlertDescription>
          </Alert>
        </Tooltip>
      </Flex>
    );
  };

  const handleSetShowSidebar = async () => {
    setAlwaysShowSidebar(!alwaysShowSidebar);
    await updateUser(session, { alwaysShowSidebar: !alwaysShowSidebar });
  };

  const handleSetShowPrediction = async () => {
    setAlwaysShowPrediction(!alwaysShowPrediction);
    await updateUser(session, { alwaysShowPrediction: !alwaysShowPrediction });
  };

  const handleAIReWriteClick = async () => {
    if (!isAuthenticated) {
      toast.error("Sign in to use this feature");
      return;
    }

    updateOnboardSteps("aiWriting", "schedule");

    setIsReWriting(true);
    const tweet = { full_text: textState?.text };
    let data = await generate(session, tweet);
    setIsReWriting(false);
    if (data?.data?.variations?.length) {
      const newText = data.data.variations[0];
      editText(newText);
    } else {
      toast.error("Failed to re-write");
    }
  };

  const handleAIGenerationIconClick = async () => {
    if (!isAuthenticated) {
      toast.error("Sign in to use this feature");
      return;
    }

    if (!textState?.text) {
      toast.error("You need to write something so the AI can continue.");
      return;
    }

    setIsGenerating(true);
    const account = getAccount(session);

    let params = {
      text: textState?.text,
      description: account?.description,
      keywords: account?.keywords,
      twUserName: account?.twUserName,
      model: session?.user?.data?.creditsAuto > 0 ? "j1-jumbo" : "j1-large",
      idUser: account?.id,
      plan: account?.subscription?.plan,
    };

    let data = await continuePost(session, params, mainContext);
    setIsGenerating(false);

    if (data?.data?.generations?.length) {
      let text = data.data.generations[0];
      let newText = textState?.text + text;
      editText(newText);

      if (session?.user?.data?.creditsAuto > 0) {
        const db = firebaseClient.firestore();
        await db
          .collection("users")
          .doc(session.user.uid)
          .update({
            creditsAuto: firebaseClient.firestore.FieldValue.increment(-1),
          });
        session.user.data.creditsAuto--;
      }
    } else {
      toast.error("Failed to generate");
    }
  };

  const handleOnSelectEmoji = (e) => {
    const emoji: string = e.native;
    if (document.execCommand) {
      if (refTextArea?.current?.focus) refTextArea.current.focus();
      var event = new Event("input");
      document.execCommand("insertText", false, emoji);
      return;
    } else {
      let newText = textState?.text + emoji;
      editText(newText);
    }
  };

  const handleUseMediaSelection = () => {
    if (selectedMedias.length === 0) {
      toast.error("Please select at least one media");
      return;
    }
    selectedMedias.forEach((media) => {
      addFileOnThread(media);
    });
    if (mode !== "autoPlug") tweetContext.setFiles(selectedMedias);
    onCloseImageUpload();
  };
  const handleOnSelectGIF = async (item) => {
    try {
      setIsGifOpen(false);
      setIsSavingGif(true);
      const res = await fetch(item.images.original.url);
      const blob = await res.blob();
      const gifFile = new File([blob], item.title, { type: blob.type });
      onFileUpload([gifFile]);
    } catch (e) {
      setIsSavingGif(false);
    }
  };

  
  const renderNewDraftButton = () => {
    return (
      <Button
        ml="2"
        variant={variant.Button.tertiaryNoBackground}
        fontWeight="400"
        color="gray.500"
        size="sm"
        onClick={async () => {
          newTweet({ text: "", labels: [], statusGhost: undefined });
          tweetContext.refresher({});
          tweetContext.setIsTweetTextChanged(true);
          onNewDraft();
        }}
        leftIcon={PlusIcon}
      >
        New draft
      </Button>
    );
  };

  const renderRichTextOptions = (props = {}) => {
    let { mb = 2 } = props;
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mb={mb}
        color={"gray.500"}
        maxW={"390px"}
      >
        <IconButton
          aria-label="make text bold"
          zIndex="9"
          variant="ghost"
          size="sm"
          icon={<Icon as={MdFormatBold} color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "sansBold")
            );
          }}
        />
        <IconButton
          aria-label="make text italic"
          zIndex="9"
          variant="ghost"
          size="sm"
          icon={<CgFormatItalic color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "sansItalic")
            );
          }}
        />
        <IconButton
          aria-label="make text italic bold"
          zIndex="9"
          variant="ghost"
          size="sm"
          icon={<MdFormatItalic color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "sansBoldItalic")
            );
          }}
        />
        <IconButton
          aria-label="make text underline"
          zIndex="9"
          variant="ghost"
          size="sm"
          icon={<MdFormatUnderlined color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "", {
                append: "\u035f",
              })
            );
          }}
        />
        <IconButton
          aria-label="make text strike through"
          zIndex="9"
          variant="ghost"
          size="sm"
          icon={<MdStrikethroughS color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "", {
                append: "\u0336",
              })
            );
          }}
        />
        <IconButton
          aria-label="make text superscript"
          zIndex="9"
          variant="ghost"
          size="sm"
          icon={<MdSuperscript color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "superscript")
            );
          }}
        />
        <IconButton
          aria-label="make text subscript"
          zIndex="9"
          variant="ghost"
          size="sm"
          icon={<MdSubscript color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "subscript")
            );
          }}
        />
        <IconButton
          aria-label="make text monospace"
          zIndex="9"
          variant="ghost"
          size="sm"
          icon={<MdCode color="gray.500" fontSize="16px" />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editText(
              replaceSelectedWithUnicode(refTextArea.current, "monospace")
            );
          }}
        />
        <Select
          placeholder="Font"
          variant="flushed"
          size="sm"
          width={"100px"}
          display="inline-block"
          border="0px"
          textAlign="center"
          onChange={(e) => {
            editText(
              replaceSelectedWithUnicode(refTextArea.current, e.target.value)
            );
          }}
        >
          <option value="normal">Normal Text</option>
          <option value="fullwidth">Fullwidth Text</option>
          <option value="fraktur">Fraktur</option>
          <option value="boldFraktur">Fraktur Bold</option>
          <option value="doubleStruck">Double Struck</option>
          <option value="script">Cursive Script</option>
          <option value="circled">Circled</option>
          <option value="squared">Squared</option>
          <option value="squaredNegative">Squared Inverse</option>
          <option value="inverted">Inverted</option>
          <option value="mirrored">Mirrored</option>
        </Select>
      </Box>
    );
  };

  const iconSize = isFullMode ? "16px" : "1.3em";

  const renderAIReWriteIcon = () => {
    return (
      <Tooltip
        placement={isFullMode ? "top" : "right"}
        hasArrow
        label="The AI will try to re-write whatever you've written so far."
      >
        <IconButton
          aria-label="re-write the tweet"
          isLoading={isReWriting}
          variant="actionAI"
          mr={1}
          size="sm"
          icon={Bolt}
          onClick={() => {
            if (isFullMode) handleAIReWriteClick();
            else onOpenRewrite();
          }}
        />
      </Tooltip>
    );
  };

  const renderAIContinueIcon = () => {
    return (
      <Tooltip
        placement={isFullMode ? "top" : "right"}
        hasArrow
        label="The AI will try to continue where you stopped. The more you have already written, the better it should be."
      >
        <IconButton
          aria-label="generate next"
          isLoading={isGenerating}
          variant="actionAI"
          size="sm"
          icon={FastForward}
          onClick={handleAIGenerationIconClick}
        />
      </Tooltip>
    );
  };

  const renderTextArea = () => {
    let containerStyles = {};
    if (mode === "engage") {
      containerStyles = {
        pb: 2,
        borderRadius: radius.lg,
        border: "1px solid",
        borderColor: colorNames.border.lightMode.light,
        _hover: {
          borderColor: colorNames.border.lightMode.hover,
        },
        _dark: {
          borderColor: "border.darkMode.light",
          _hover: {
            borderColor: colorNames.border.darkMode.hover,
          },
        },
        bg: engageContainerBg,
      };
    }
    return (
      <Box {...containerStyles}>
        <Textarea
          {...dragProps}
          {...{
            borderRadius: radius.lg,
            border: isFullMode || mode === "engage" ? "none" : "1px solid",
            borderColor: colorNames.border.lightMode.light,
            _hover: {
              borderColor: isFullMode
                ? "none"
                : colorNames.border.lightMode.hover,
            },
            _dark: {
              borderColor: "border.darkMode.light",
              color: "text.darkMode.standard",
              _hover: {
                borderColor: colorNames.border.darkMode.hover,
              },
            },
          }}
          variant={isFullMode ? "unstyled" : "taplio"}
          ref={refTextArea}
          transition="height none"
          id="tweet-area"
          value={textState?.text}
          maxH={
            isShowThreadTweetPreview &&
              browser?.getBrowserName()?.includes("Safari")
              ? "calc(100vh - 500px)"
              : "calc(100vh - 250px)"
          }
          dir={getAccount(session)?.isRtl ? "rtl" : "ltr"}
          pr={isFullMode ? 5 : 2}
          pb={10}
          onKeyDown={handleKeyDown}
          onKeyUp={(e) => {
            if (e.keyCode === 27) {
              setIsSuggestionOpen(false);
              setSuggestions([]);
            }
          }}
          onChange={(e) => {
            if (!disableSuggestion) debounced(e);

            if (!needReset) {
              editText(e.target.value);
            }
          }}
          h={"200px"}
          style={{ minHeight: "100%" }}
          resize={"vertical"}
          isDisabled={
            isDisabled || (!isAuthenticated && !tweetContext.isIncognitoMode)
          }
          placeholder={mode == "tweet" ? placeHolder : "Write here"}
          onPaste={(e) => {
            if (e?.clipboardData?.files?.length > 0) {
              e.preventDefault();
              onFileUpload(e.clipboardData.files);
            }
          }}
          textStyle={"body.medium.standard"}
          fontSize="14px"
          fontFamily="'Source Sans Pro', Helvetica, sans-serif, serif"
        />
        {mode === "engage" ? (
          <AIReplyButtonsMemoed
            tweetText={tweet?.full_text}
            onNewText={(newText) =>
              editText((textState?.text ? textState?.text : "") + newText)
            }
          />
        ) : null}
      </Box>
    );
  };

  const selectTweetInThread = (text, tweet) => {
    let cursorPos = textState?.text?.indexOf(text);
    if (refTextArea.current) {
      refTextArea.current.setSelectionRange(cursorPos, cursorPos);
      refTextArea.current.focus();
      refTextArea.current.setSelectionRange(
        cursorPos,
        cursorPos + text?.length
      );
    }
  };

  const handleImageClick = (id: string) => {
    const isNotImage = id.includes("vid-") || id.includes("gif-");
    if (isNotImage) {
      setSelectedImageId("");
      return;
    }

    setSelectedImageId(id);
    onOpenImageEdit();
  };

  const handleSendPrompt = async (prompt: any) => {
    if (tweetContext?.refComposer?.current?.textState()?.text?.length < 2) {
      toast.error("Please write a tweet first");
      return;
    }

    prompt.systemPrompt =
      (prompt?.systemPrompt || "") +
      `\n- write and generate the user's post in ${getAccount(session)?.languagePreferred || "English"
      }. DO NOT USE ANY OTHER LANGUAGE. ONLY ${getAccount(session)?.languagePreferred || "English"
      }.`;
    await chatContext?.chatAssistRef?.current?.setMessages([]);
    chatContext?.onOpen();
    chatContext?.chatAssistRef?.current?.sendMessage(
      prompt?.userPrompt,
      prompt?.systemPrompt
    );
  };

  const renderProbabilityIndicator = (
    proba_outperforming,
    confidence,
    showText = false
  ) => {
    if (!proba_outperforming) {
      return <></>;
    }

    let textProps = {
      color: "gray.400",
      ml: 2,
      fontSize: "10px",
      fontWeight: "600",
      h: "12px",
    };

    let icon;
    let text;

    if (proba_outperforming == -1) {
      icon = (
        <Box
          {...gradientProps}
          bg={`linear-gradient(white, white) padding-box, linear-gradient(135deg, ${theme.colors.gray[400]}, ${theme.colors.gray[500]}) border-box`}
        ></Box>
      );
      text = "no prediction";
    } else if (confidence < 0.1) {
      icon = (
        <Box
          {...gradientProps}
          bg={`linear-gradient(white, white) padding-box, linear-gradient(135deg, ${theme.colors.gray[400]}, ${theme.colors.gray[500]}) border-box`}
        ></Box>
      );
      text = "Off Topic";
    } else if (proba_outperforming < 0.6) {
      icon = (
        <Box
          {...gradientProps}
          bg={`linear-gradient(white, white) padding-box, linear-gradient(135deg, ${theme.colors.blue[400]}, ${theme.colors.blue[500]}) border-box`}
        ></Box>
      );
      text = "OK";
    } else if (proba_outperforming < 0.75) {
      icon = (
        <Box
          {...gradientProps}
          bg={`linear-gradient(white, white) padding-box, linear-gradient(135deg, ${theme.colors.green[400]}, ${theme.colors.green[500]}) border-box`}
        ></Box>
      );
      text = "Above Average";
    } else {
      return (
        <Flex alignItems="center" position="relative">
          <Icon
            as={BsCheck}
            fontSize="lg"
            color="white"
            position="absolute"
            left="0px"
            top="0px"
          />
          <Box {...gradientProps} border="9px solid"></Box>
          {showText && <Text {...textProps}>High Potential</Text>}
        </Flex>
      );
    }

    return (
      <Flex alignItems="center" position="relative">
        {icon}
        {showText && <Text {...textProps}>{text}</Text>}
      </Flex>
    );
  };

  React.useEffect(() => {
    const hasMedia = textState?.text?.includes("[img:");
    const showPreviewMobile = tweetContext.isPreviewOpen && isTooSmallForThread;
    const showPreviewDesktop =
      !disableThread &&
      (textState?.threadTweets?.length > 1 ||
        (alwaysShowSidebar && textState?.text?.length > 0) ||
        isFullMode ||
        hasMedia) &&
      tweetContext.isOpen &&
      mode == "tweet";
    let isShowThreadTweetPreview = isTooSmallForThread
      ? showPreviewMobile
      : showPreviewDesktop;

    setIsShowThreadTweetPreview(
      isTooSmallForThread ? showPreviewMobile : showPreviewDesktop
    );
  }, [
    textState,
    tweetContext.isPreviewOpen,
    tweetContext.isOpen,
    disableThread,
    alwaysShowSidebar,
    isFullMode,
    mode,
    isTooSmallForThread,
  ]);
  let dragProps = {};
  if (mode == "tweet") dragProps = { ...getRootProps({ style }) };

  let colorPopularityOutperforming = "black";
  if (popularity?.confidence < 0.1) colorPopularityOutperforming = "gray";
  else if (popularity?.proba_outperforming < 0.6)
    colorPopularityOutperforming = "blue.400";
  else colorPopularityOutperforming = "green.500";

  const lgFrom = "#D50071";
  const lgTo = "#E1C800";
  const bgColor = theme.colors.white;
  let gradientProps = {
    w: "18px",
    h: "18px",
    color: "gray.900",
    border: "7px solid",
    borderColor: "transparent",
    borderRadius: "full",
    background: `linear-gradient(${bgColor}, ${bgColor}) padding-box, linear-gradient(135deg, ${lgFrom}, ${lgTo}) border-box`,
    _active: {
      transform: "scale(1.04)",
      opacity: "0.7",
    },
    _focus: {
      boxShadow: "none",
    },
  };

  const suggestionBoxWidth = 260;
  const textAreaWidth = refTextArea?.current?.clientWidth ?? 0;

  const suggestionsUI = isSuggestionsOpen && suggestions?.length > 0 && (
    <Box
      position="absolute"
      zIndex="popover"
      maxH="44"
      backgroundColor={backgroundColor}
      overflow="auto"
      border="1px"
      borderRadius="md"
      boxShadow="md"
      width="260px"
      left={
        (position.left + suggestionBoxWidth > textAreaWidth
          ? textAreaWidth - suggestionBoxWidth
          : position.left) + "px"
      }
      borderColor={borderColor}
      padding="1"
      top={position.top + 24 + "px"}
      tabIndex={1}
      ref={suggestionPopupRef}
    >
      <Suggestions suggestions={suggestions} onSelect={handleSelect} />
    </Box>
  );

  const [isOpenAiReWrite, isOpenAiReWriteSet] = useState(false);
  return (
    <Box h={isFullMode ? "100%" : "unset"}>

      {isShowThreadTweetPreview && (
        <ThreadTweetPreviewSidebar
          selectTweetInThread={selectTweetInThread}
          textState={textState}
          alwaysShowSidebar={alwaysShowSidebar}
          handleSetShowSidebar={handleSetShowSidebar}
          mode={mode}
          disableThread={disableThread}
          disablePreview={disablePreview}
          id={refId?.current}
        />
      )}

      {!disableHeader && (
        <>
          {isFullMode ? (
            <Box
              mb={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              textStyle={textStyle["h4.bold.standard"]}
            >
              <Flex alignItems={"center"}>
                <Flex justifyContent="flex-start" alignItems="center" mr={3}>
                  <EditIconComponent {...{ width: 16, height: 16 }} />
                  <Text
                    ml={2}
                  >
                    Content
                  </Text>
                </Flex>
                {renderNewDraftButton()}
              </Flex>
              {createDraft &&
                (isSaving ? (
                  <Box ml={1}>
                    <Spinner
                      size="xs"
                      {...{
                        color: colors.text.lightMode.inactive,
                        _dark: {
                          color: colors.text.darkMode.inactive,
                        },
                      }}
                    />
                  </Box>
                ) : (
                  <Text
                    align="right"
                    textStyle={textStyle["body.medium.light"]}
                    {...{
                      color: colors.text.lightMode.inactive,
                      _dark: {
                        color: colors.text.darkMode.inactive,
                      },
                    }}
                  >
                    Saved as draft @{moment().format("HH:mm")}
                  </Text>
                ))}
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Flex alignItems="center">
                <Text
                  fontWeight="700"
                  textStyle={textStyle["body.bold.standard"]}
                >
                  Your content{" "}
                </Text>
              </Flex>
              {renderNewDraftButton()}
            </Box>
          )}
        </>
      )}
      <Box h={"85%"} position="relative">
        {mode == "tweet" ? (
          isMobile ? (
            <>
              {isFullMode ? (
                <Center w="100%" h="100%">
                  <Box w="100%" h="100%" maxW="500px" position="relative">
                    {renderTextArea()}
                    {suggestionsUI}
                  </Box>
                </Center>
              ) : (
                <>
                  {renderTextArea()}
                  {suggestionsUI}
                </>
              )}
            </>
          ) : (
            <>
              {isFullMode ? (
                <Center w="100%" h="100%">
                  <Box w="100%" h="100%" maxW="500px" position="relative">
                    {renderTextArea()}
                    {suggestionsUI}
                  </Box>
                </Center>
              ) : (
                <>
                  {renderTextArea()}
                  {suggestionsUI}
                </>
              )}
            </>
          )
        ) : (
          <>
            {renderTextArea()}
            {suggestionsUI}
          </>
        )}
      </Box>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={isFullMode ? "center" : "space-between"}
        minHeight={10}
      >
        <Flex justifyContent="flex-start" alignItems="center" w="129px">
          {(!textState?.threadTweets ||
            textState?.threadTweets?.length <= 1 ||
            mode == "taplio-push") &&
            !["autoDm", "dm"].includes(mode) &&
            !isFullMode &&
            (mode != "taplio-push" || nbChar > 2500) && (
              <Flex
                flexWrap="nowrap"
                color={nbChar > postCharMax ? "red" : "gray.400"}
                fontWeight={nbChar > postCharMax ? "bold" : "regular"}
                fontSize="xs"
              >
                {nbChar} / {postCharMax}
              </Flex>
            )}
          {createDraft &&
            !isFullMode &&
            (isSaving ? (
              <Box ml={3} fontSize="xs">
                <Spinner size="xs" color="gray.400" />
              </Box>
            ) : (
              <Flex
                alignItems="center"
                gap={1}
                color="gray.400"
                fontSize="xs"
                ml={2}
              >
                saved
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.75 3.21875C15.0625 3.53125 15.0625 4 14.75 4.28125L6.5 12.5312C6.21875 12.8438 5.75 12.8438 5.46875 12.5312L1.21875 8.28125C0.90625 8 0.90625 7.53125 1.21875 7.21875C1.5 6.9375 1.96875 6.9375 2.25 7.21875L6 10.9688L13.7188 3.21875C14 2.9375 14.4688 2.9375 14.75 3.21875Z"
                    fill="currentColor"
                  />
                </svg>
              </Flex>
            ))}
        </Flex>
        <Box
          position={isFullMode ? "absolute" : "relative"}
          mt={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          bottom={isFullMode ? "4px" : "0px"}
          left={isFullMode ? "50%" : "0px"}
          transform={isFullMode ? "translateX(-50%)" : ""}
        >
          {!disableReWrite &&
            !isFullMode &&
            isAllowed(session?.user, "chat") ? (
            <>
              <Tooltip
                placement="right"
                label="Use AI to improve or continue your tweet."
                hasArrow
              >
                <Button
                  w="48px"
                  onClick={() => isOpenAiReWriteSet(!isOpenAiReWrite)}
                  as={IconButton}
                  size="sm"
                  variant="actionAI"
                  {...{
                    _hover: {
                      color: colors.ai[700],
                      bg: colors.ai[50],
                    },
                    _dark: {
                      color: "text.darkMode.standard",
                      bg: "transparent",
                      _hover: {
                        color: colors.ai[200],
                        bg: hexToHexa(colors.ai[900], 0.2),
                      },
                      _active: {
                        color: colors.ai[300],
                        bg: hexToHexa(colors.ai[900], 0.4),
                      },
                    },
                  }}
                  icon={
                    <>
                      <Flex mx="2">
                        <AiButtonSVGIcon />
                        {/* <ChevronDownIcon/> */}
                        <motion.div
                          animate={{
                            rotate: isOpenAiReWrite ? 180 : 0,
                          }}
                          transition={{
                            type: "spring",
                            duration: 0.5,
                            bounce: 0,
                          }}
                        >
                          <Box ml=".5" opacity={0.8} color={"#BB66A5;"}>
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.6875 7.71875L6.71875 11.7188C6.5 11.9062 6.25 12 6 12C5.71875 12 5.46875 11.9062 5.28125 11.7188L1.3125 7.71875C1 7.4375 0.90625 7 1.0625 6.625C1.21875 6.25 1.59375 6 2 6H9.96875C10.375 6 10.7188 6.25 10.875 6.625C11.0312 7 10.9688 7.4375 10.6875 7.71875Z"
                                fill="currentColor"
                              />
                            </svg>
                          </Box>
                        </motion.div>
                      </Flex>
                    </>
                  }
                />
              </Tooltip>
            </>
          ) : 
            null}
          {!disableSnippets && (
            <IconButton
              aria-label="open snippets"
              variant="action"
              size="composer"
              icon={
                <Icon viewBox="0 0 16 16">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 14.5H9C9.25 14.5 9.5 14.2812 9.5 14V12H11V14C11 15.125 10.0938 16 9 16H2C0.875 16 0 15.125 0 14V7C0 5.90625 0.875 5 2 5H4V6.5H2C1.71875 6.5 1.5 6.75 1.5 7V14C1.5 14.2812 1.71875 14.5 2 14.5ZM5 2C5 0.90625 5.875 0 7 0H14C15.0938 0 16 0.90625 16 2V9C16 10.125 15.0938 11 14 11H7C5.875 11 5 10.125 5 9V2ZM7 9.5H14C14.25 9.5 14.5 9.28125 14.5 9V2C14.5 1.75 14.25 1.5 14 1.5H7C6.71875 1.5 6.5 1.75 6.5 2V9C6.5 9.28125 6.71875 9.5 7 9.5Z"
                      fill="currentColor"
                    />
                  </svg>
                </Icon>
              }
              onClick={() => {
                setIsSnippetsOpen(!isSnippetsOpen);
              }}
            />
          )}
          {isSnippetsOpen && (
            <Snippets
              isSnippetsOpen={isSnippetsOpen}
              setIsSnippetsOpen={setIsSnippetsOpen}
              pushText={pushText}
            />
          )}
          {!disableRichText && (
            <Tooltip hasArrow label="Add rich text">
              <IconButton
                aria-label="add rich text"
                zIndex="9"
                variant="action"
                size="composer"
                size="sm"
                icon={
                  <Icon viewBox="0 0 14 14">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 0.75V3.25C14 3.6875 13.6562 4 13.25 4C12.8125 4 12.5 3.6875 12.5 3.25V1.5H7.75V12.5H9.25C9.65625 12.5 10 12.8438 10 13.25C10 13.6875 9.65625 14 9.25 14H4.75C4.3125 14 4 13.6875 4 13.25C4 12.8438 4.3125 12.5 4.75 12.5H6.25V1.5H1.5V3.25C1.5 3.6875 1.15625 4 0.75 4C0.3125 4 0 3.6875 0 3.25V0.75C0 0.34375 0.3125 0 0.75 0H13.25C13.6562 0 14 0.34375 14 0.75Z"
                        fill="currentColor"
                      />
                    </svg>
                  </Icon>
                }
                onClick={() => {
                  keepRefreshSelection(refTextArea.current);
                  setIsRichTextOpen(!isRichTextOpen);
                }}
              />
            </Tooltip>
          )}
          {!disableImage && (
            <IconButton
              aria-label="add image"
              isLoading={isSavingImage}
              isDisabled={isSavingImage || isSavingGif}
              variant="action"
              size="composer"
              icon={<HiOutlineDocumentAdd size={18} />}
              onClick={() => {
                setTabIndex(0);
                setSelectedMedias([]);
                onOpenImageUpload();
              }}
            />
          )}
          <IconButton
            ref={refTriggerEmoji}
            aria-label="add emoji"
            variant="action"
            size="composer"
            size="sm"
            icon={
              <Icon viewBox="0 0 16 16">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 11C9.15625 11 9.96875 10.4688 10.4375 9.96875C10.7188 9.65625 11.1875 9.625 11.5 9.90625C11.8125 10.1875 11.8125 10.6562 11.5312 10.9688C10.8438 11.7188 9.65625 12.5 8 12.5C6.3125 12.5 5.125 11.7188 4.4375 10.9688C4.15625 10.6562 4.15625 10.1875 4.46875 9.90625C4.78125 9.625 5.25 9.65625 5.53125 9.96875C6 10.4688 6.8125 11 8 11ZM6.5 6.5C6.5 7.0625 6.0625 7.5 5.5 7.5C4.9375 7.5 4.5 7.0625 4.5 6.5C4.5 5.96875 4.9375 5.5 5.5 5.5C6.0625 5.5 6.5 5.96875 6.5 6.5ZM9.5 6.5C9.5 5.96875 9.9375 5.5 10.5 5.5C11.0625 5.5 11.5 5.96875 11.5 6.5C11.5 7.0625 11.0625 7.5 10.5 7.5C9.9375 7.5 9.5 7.0625 9.5 6.5ZM16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8 1.5C4.40625 1.5 1.5 4.4375 1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C11.5625 14.5 14.5 11.5938 14.5 8C14.5 4.4375 11.5625 1.5 8 1.5Z"
                    fill="currentColor"
                  />
                </svg>
              </Icon>
            }
            onClick={() => {
              setIsOpenEmoji(!isOpenEmoji);
            }}
          />
          {isOpenEmoji && (
            <Box
              position="absolute"
              right="0"
              top={isFullMode ? "-430px" : "30px"}
              w="340px"
              ref={refEmoji}
              zIndex="12"
            >
              <NimblePicker
                theme={nimblePickerTheme}
                set="twitter"
                data={data}
                sheetSize={64}
                autoFocus={true}
                style={{ zIndex: "12" }}
                onSelect={handleOnSelectEmoji}
              />
            </Box>
          )}

          {!disableGif && (
            <>
              <IconButton
                aria-label="add gif"
                variant="action"
                size="composer"
                isDisabled={isSavingGif || isSavingImage}
                isLoading={isSavingGif}
                icon={
                  <Icon viewBox="0 0 18 14">
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 0H2C0.875 0 0 0.90625 0 2V12C0 13.125 0.875 14 2 14H16C17.0938 14 18 13.125 18 12V2C18 0.90625 17.0938 0 16 0ZM16.5 12C16.5 12.2812 16.25 12.5 16 12.5H2C1.71875 12.5 1.5 12.2812 1.5 12V2C1.5 1.75 1.71875 1.5 2 1.5H16C16.25 1.5 16.5 1.75 16.5 2V12ZM14.5 4.125H11.75C11.375 4.125 11.125 4.40625 11.125 4.75V9.25C11.125 9.625 11.375 9.875 11.75 9.875C12.0938 9.875 12.375 9.625 12.375 9.25V7.625H14C14.3438 7.625 14.625 7.375 14.625 7C14.625 6.65625 14.3438 6.375 14 6.375H12.375V5.375H14.5C14.8438 5.375 15.125 5.125 15.125 4.75C15.125 4.40625 14.8438 4.125 14.5 4.125ZM9.5 4.125C9.125 4.125 8.875 4.40625 8.875 4.75V9.25C8.875 9.625 9.125 9.875 9.5 9.875C9.84375 9.875 10.125 9.625 10.125 9.25V4.75C10.125 4.40625 9.84375 4.125 9.5 4.125ZM7.5 6.625H5.6875C5.34375 6.625 5.0625 6.90625 5.0625 7.25C5.0625 7.625 5.34375 7.875 5.6875 7.875H6.875V8.3125C6.21875 8.78125 5.125 8.71875 4.59375 8.15625C4.28125 7.84375 4.125 7.4375 4.125 7C4.125 6.59375 4.28125 6.1875 4.59375 5.875C5.1875 5.25 6.21875 5.25 6.84375 5.875C7.09375 6.125 7.5 6.125 7.71875 5.875C7.96875 5.625 7.96875 5.21875 7.71875 4.96875C6.65625 3.90625 4.78125 3.90625 3.6875 4.96875C3.15625 5.53125 2.875 6.25 2.875 7C2.875 7.78125 3.15625 8.5 3.6875 9.0625C4.25 9.59375 5 9.875 5.8125 9.875C6.625 9.875 7.375 9.59375 7.9375 9.0625C8.03125 8.9375 8.125 8.78125 8.125 8.59375V7.25C8.125 6.90625 7.84375 6.625 7.5 6.625Z"
                        fill="currentColor"
                      />
                    </svg>
                  </Icon>
                }
                onClick={() => {
                  setIsGifOpen(!isGifOpen);
                }}
              />
              {isGifOpen && (
                <Box
                  position="absolute"
                  right="0px"
                  top={isFullMode ? "-400px" : "40px"}
                  p="2"
                  borderRadius="xl"
                  border="1px"
                  borderColor={gifContainerBorder}
                  background={gifContainerBg}
                  ref={refGif}
                  zIndex="12"
                >
                  <GifBox onSelect={handleOnSelectGIF} />
                </Box>
              )}
            </>
          )}
          {isFullMode && (
            <>
              <Text
                mx={4}
                fontSize={"sm"}
                textStyle={textStyle["body.medium.inactive"]}
              >
                |
              </Text>
              {!disableAiContinue && renderAIContinueIcon()}
              <IconButton
                aria-label="undo"
                variant="actionAI"
                size="sm"
                icon={<GrUndo size={iconSize} />}
                onClick={undoTextState}
                disabled={!canUndo}
              />
              {!disableReWrite && renderAIReWriteIcon()}
              <IconButton
                aria-label="redo"
                variant="actionAI"
                size="sm"
                icon={<GrRedo size={iconSize} />}
                onClick={redoTextState}
                disabled={!canRedo}
              />
            </>
          )}
        </Box>
      </Box>
      {isRichTextOpen && (
        <Box>
          {isFullMode && (
            <Center position={"absolute"} bottom={"42px"} left={"12px"}>
              <Flex
                justifyContent="center"
                alignItems="center"
                flexDir="column"
              >
                {
                  <Flex mb={2} bg={gifContainerBg} rounded="xl" p={1} px={4}>
                    {renderRichTextOptions({ mb: 0 })}
                  </Flex>
                }
              </Flex>
            </Center>
          )}
          {!isFullMode && renderRichTextOptions()}
        </Box>
      )}
      {isPremium && (
        <Text
          fontWeight="700"
          color={colors.primary.lightMode[300]}
          bg={colors.primary.lightMode[80]}
          as={"span"}
          fontSize={"xs"}
        >
          {" "}
          <TiTickOutline
            style={{
              display: "inline-block",
              position: "relative",
              top: "2px",
            }}
          />{" "}
          Long form post enabled
        </Text>
      )}
      {nbChar > postCharMax && !isPremium && (
        <Text
          textStyle={textStyle["body.medium.light"]}
          color="gray.600"
          mt={2}
          fontSize="xs"
        >
          <i>
            Long form posts on X/Twitter are only available to X/Twitter premium
            users. If you are a premium user and seeing this, please login &
            logout of TweetHunter and try again.
          </i>
        </Text>
      )}

      {nbChar > postCharMax && isPremium && (
        <Text
          textStyle={textStyle["body.medium.light"]}
          color="gray.600"
          mt={2}
          fontSize="xs"
        >
          <i>Long form posts are limited to 25000 characters on X/Twitter.</i>
        </Text>
      )}

      {!disableImage && textState?.files?.length > 0 && !isFullMode && (
        <Box
          position="relative"
          mt={1}
          display="flex"
          justifyContent="flex-end"
          overflow="visible"
          h="32px"
        >
          {textState?.files.map((x) => (
            <ImageThumbnail
              key={"ImageThumbnail-" + x}
              id={x}
              primaryAction={imageEditor && "edit"}
              openImageEditor={() => handleImageClick(x)}
            />
          ))}
        </Box>
      )}
      <Collapse in={isOpenAiReWrite} animateOpacity>
        <Flex
          alignItems="center"
          gap={2}
          px="4"
          pt="2"
          pb="2"
          border="none"
          textStyle={textStyle["body.bold.standard"]}
        >
          Improve your tweet with AI:
        </Flex>
        <Grid
          gridTemplateColumns={["repeat(1, 160px)", "repeat(2, 1fr)"]}
          rowGap="0"
          columnGap="2"
          p="0"
        >
          {prompts.map(({ icon: Icon, ...prompt }) => (
            <Button
              key={prompt.title}
              variant="actionAI"
              leftIcon={<Icon />}
              pl="4"
              w="100%"
              justifyContent="left"
              onClick={() => handleSendPrompt(prompt)}
            >
              {prompt.title}
            </Button>
          ))}
        </Grid>
      </Collapse>
      {/* <Box display="none"> */}
      {/* <Box display={files?.length > 0 ? "block" : "none"}> */}
      <Modal
        isOpen={isOpenImageUpload}
        onClose={onCloseImageUpload}
        size={tabIndex === 0 ? "md" : "2xl"}
        scrollBehavior={tabIndex === 1 && medias.length > 0 && "inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <Tabs
            onChange={(index) => {
              setTabIndex(index);
              if (index !== 1 || hasFetchedMedia) return;

              fetchData();
            }}
            overflowY={tabIndex === 1 && "scroll"}
          >
            <TabList>
              <Tab>Upload / Generate</Tab>
              <Tab>My Assets</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ModalHeader>Add an image, video or document</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  <FileUploader
                    session={session}
                    mode={mode}
                    setIsSavingImage={setIsSavingImage}
                    setIsSavingGif={setIsSavingGif}
                    isFileValid={isFileValid}
                    addFileOnThread={addFileOnThread}
                    onCloseImageUpload={onCloseImageUpload}
                    refData={refData}
                  />
                  <Text textAlign="center" mb={4}>
                    or
                  </Text>
                  <Text textAlign="center" mb={4} fontWeight="bold">
                    Generate an image with AI
                  </Text>
                  <GenerateImage
                    content={textState?.text}
                    onImageGenerated={(fileName) => {
                      addFileOnThread(fileName);
                      onCloseImageUpload();
                    }}
                  />
                </ModalBody>
              </TabPanel>
              <TabPanel>
                <ModalHeader>Select an asset from your library</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                  {!hasFetchedMedia && (
                    <Box
                      width={"100%"}
                      mt={6}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Loader
                        color={
                          colorMode === "dark"
                            ? hslToHsla(colors.primary.lightMode[100], 1)
                            : hslToHsla(colors.primary.lightMode[400], 1)
                        }
                      />
                    </Box>
                  )}
                  {hasFetchedMedia && !!medias.length && (
                    <MediaMasonry
                      colorMode={colorMode}
                      medias={medias}
                      tweetContext={tweetContext}
                      modalMode
                      setSelectedMedias={setSelectedMedias}
                      selectedMedias={selectedMedias}
                    />
                  )}
                </ModalBody>
              </TabPanel>
            </TabPanels>
          </Tabs>
          {tabIndex === 1 && (
            <ModalFooter paddingTop={"10px !important"}>
              <Button
                onClick={handleUseMediaSelection}
                style={{
                  ...(selectedMedias.length > 0 && {
                    color: colors.success.lightMode[400],
                    border: `2px solid ${colors.success.lightMode[400]}`,
                  }),
                }}
              >
                Use selection ({selectedMedias.length})
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
      {!!selectedTweet?.full_text && getSimilarityWarning()}
      {!!tweetError?.text && !isFullMode && (
        <Text
          mt={0}
          color={
            tweetError?.type == "error"
              ? "red"
              : tweetError?.type == "warning"
                ? "orange.500"
                : "gray.500"
          }
          fontSize="sm"
        >
          <i>{tweetError?.text}</i>
        </Text>
      )}
      <ImageEditPopup
        isOpen={isOpenImageEdit}
        onClose={onCloseImageEdit}
        imageId={selectedImageId}
        onSave={(newMedia) => {
          editText(textState.text.replace(selectedImageId, newMedia.id));
          onCloseImageEdit();
        }}
      />
      <Modal isOpen={isOpenRewrite} onClose={onCloseRewrite}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Just to be sure</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            This will override your current content and re-write it using AI.
          </ModalBody>

          <ModalFooter>
            <Button
              variant={"primary"}
              onClick={() => {
                onCloseRewrite();
                handleAIReWriteClick();
              }}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
});
