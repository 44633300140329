import xss from "xss";

export const sanitizeInput = (input: string) => {
  if (!input) return input;

  // First pass: Basic XSS sanitization with a minimal whitelist
  const sanitized = xss(input, {
    whiteList: {
      // Allow basic formatting tags that are safe
      p: [],
      br: [],
      strong: [],
      em: [],
      u: [],
      // Allow links with href only
      a: ['href'],
      // Allow basic lists
      ul: [],
      ol: [],
      li: [],
      // Allow basic blockquotes
      blockquote: [],
    },
    stripIgnoreTag: false,
    stripIgnoreTagBody: ['script', 'style', 'iframe', 'object', 'embed'],
    // Allow safe attributes
    allowList: {
      a: ['href', 'target', 'rel'],
    },
  });

  // Second pass: Additional security measures and unescape special characters
  return (
    sanitized
      // Remove any remaining potentially dangerous attributes
      .replace(/on\w+="[^"]*"/g, '')
      .replace(/on\w+='[^']*'/g, '')
      .replace(/javascript:/gi, '')
      .replace(/data:/gi, '')
      // Unescape HTML entities back to their original characters
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
  );
};
