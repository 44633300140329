import {
    Flex,
    Icon,
    IconButton,
    Image,
    Text,
    useColorModeValue,
    useDisclosure
} from "@chakra-ui/react";
import { AltTextPopup } from 'components/popups/altTextPopup';
import { motion } from "framer-motion";
import React from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { BsCardText } from 'react-icons/bs';

interface Props {
    id: string;
    openImageEditor?: () => void;
    primaryAction?: "edit";
}

export const ImageThumbnail: React.FC<Props> = ({ id, openImageEditor, primaryAction }) => {

    const [size, setSize] = React.useState<"small" | "large">("small");
    const bgColor = useColorModeValue("gray.800", "gray.100");
    const textColor = useColorModeValue("gray.100", "gray.800");
    const isNotImage = id.includes("vid-") || id.includes("gif-");

    const { isOpen: isAltTextPopupOpen, onOpen: openAltTextPopup, onClose: closeAltTextPopup } = useDisclosure();

    return (
        <motion.div
            animate={{
                width: size == "small" ? "32px" : "150px",
                height: size == "small" ? "32px" : "150px",
            }}
            transition={{
                duration: 0.1,
                ease: "easeOut",
            }}
            style={{
                marginRight: "8px",
                minWidth: size == "small" ? "32px" : "150px",
                zIndex: size == "small" ? 0 : 20,
                cursor: openImageEditor ? (isNotImage ? "default" : "pointer") : "default"
            }}
            key={id}
            onClick={() => {
                if (primaryAction === 'edit' && openImageEditor) {
                    openImageEditor();
                }
            }}
        >
            <Flex
                h="100%"
                w="100%"
                p={size == "small" ? "0" : 1}
                bg={bgColor}
                borderRadius="10px"
                flexDirection="column"
                position="relative"
                alignItems="center"
                onMouseEnter={() => {
                    setSize("large");
                }}
                onMouseLeave={() => {
                    setSize("small");
                }}
            >
                {
                    size == "large" && (
                        <Text fontSize="sm" m={2} color={textColor}>
                            {"[img:" + id + "]"}
                        </Text>
                    )
                }

                {
                    id.includes("vid-") ? (
                        size == "large" && (
                            <Text color="white">[video]</Text>
                        )
                    ) : (
                        <>
                            <Image
                                src={"https://ez4cast.s3.eu-west-1.amazonaws.com/userUpload/" + id}
                                h="100%"
                                w="100%"
                                borderRadius="10px"
                                objectFit="cover"
                                fit="cover"
                                overflow="hidden"
                                opacity={0.8}
                            />
                            {!isNotImage && (<>

                                {primaryAction === "edit" && openImageEditor &&
                                    <Icon
                                        aria-label="edit"
                                        height="4"
                                        width="4"
                                        color="white"
                                        as={AiOutlineEdit}
                                        position="absolute"
                                        top={size === "small" ? "1.5" : "12"}
                                        right={size === "small" ? "1.5" : "2.5"}
                                    />
                                }

                                {!primaryAction && size == "large" &&
                                    <IconButton
                                        size="sm"
                                        aria-label="alt text"
                                        icon={<BsCardText />}
                                        position="absolute"
                                        bottom="2"
                                        right="2"
                                        onClick={openAltTextPopup}
                                        borderRadius="full"
                                        color="white"
                                        bg={bgColor}
                                        _hover={{ bg: bgColor }}
                                    />
                                }


                            </>
                            )}
                            <AltTextPopup
                                isOpen={isAltTextPopupOpen}
                                onClose={closeAltTextPopup}
                                imageId={id}
                            />
                        </>
                    )
                }
            </Flex>
        </motion.div>
    )
}