import React, { memo, useState } from 'react';
import {
    Button,
    Stack,
    Box,
    Text,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    IconButton,
    Link, Icon,
    useDisclosure,
    useMediaQuery,
    FormLabel,
    ModalFooter,
    VStack,
    useColorModeValue,
    Image,
    Flex,
    theme,
    Switch,
    FormControl,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    NumberInput,
    NumberInputField,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Center,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    ResponsiveValue,
    StackProps
} from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/tooltip";
import { useSession } from "next-auth/client";
import toast from "react-hot-toast";
import { firebaseClient } from "../../firebaseClient";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Tweet from "../../components/tweets/masonry/tweet";
import { TweetComposer } from "../../components/tweet-composer";
import { cancelPostPublishActions, unschedule } from "../../controllers/schedule";
import dateFormat from 'dateformat';
import { format, formatDistance } from "date-fns";
import { AiOutlineDelete } from 'react-icons/ai';
import * as analytics from "../../utils/analytics";
import { TimeIcon, SmallCloseIcon, SmallAddIcon } from '@chakra-ui/icons'
import { BiGhost, BiPauseCircle, BiErrorAlt } from 'react-icons/bi';
import { FiExternalLink } from 'react-icons/fi';
import { useRouter } from "next/dist/client/router";
import moment from "moment-timezone";
import { isAllowed, getPlan } from "../../controllers/subscription";
import { motion } from "framer-motion"
import { TweetContext } from "../../context/tweetContext";
import { ChevronDownIcon } from '@chakra-ui/icons'
import { QuestionIcon } from '@chakra-ui/icons';
import Thread from "../../components/tweets/masonry/thread";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getAccount } from "../../utils/sessionHelper";
import * as Sentry from "@sentry/nextjs";
import { MdCancelScheduleSend } from "react-icons/md"
import { BiCopy } from "react-icons/bi";
import { DeleteIcon } from 'components/icons/DeleteIcon';
import { textStyle } from 'theme/names';


export function TweetCard({ tweet, removeFromList, setList, list }) {
    const [session, loading] = useSession();
    const tweetContext: any = React.useContext(TweetContext);
    const [isCollapsed, setIsCollapsed] = React.useState(true);
    const [isConfirming, setIsConfirming] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    const cardIconStackStyle: Partial<StackProps> = {
        position: "absolute",
        right: "5px",
        top: tweet?.isError ? "35px" : "5px",
        zIndex: "10",
    }

    const openTweet = (tweet) => {
        if (tweet?.text) {
            tweetContext.setSelectedTweetPreview(tweet);
            tweetContext.onOpenTweetPreview();
        }
    }
    return (
        <Box w="100%">
            <Button
                m={0}
                p={4}
                pb={0}
                height={"100%"}
                maxHeight={isCollapsed ? "140px" : "100%"}
                minHeight={tweet.isError ? "100px" : "auto"}
                overflow="hidden"
                width="100%"
                minH={tweet.status == "sent" ? "140px" : "95px"}
                as="div"
                alignItems="left"
                justifyContent="flex-start"
                onClick={(e) => {
                    e.stopPropagation();
                    if (tweet.status == "draft") {
                        // console.log("clicked draft");
                        tweetContext.setTabIndex(0);
                        tweetContext.newTweet(tweet, tweet.id);
                        tweetContext.refresher({});
                        tweetContext.setIsTweetTextChanged(true);
                    }
                    else {
                        // console.log("isCollapsed: " + isCollapsed);
                        setIsCollapsed(!isCollapsed);
                    }
                }}
            >
                <Text
                    textStyle={textStyle["body.medium.light"]}
                    textAlign="left"
                    alignItems="left"
                    height="100%"
                    fontWeight="normal"
                    noOfLines={1} wordBreak="break-all"
                    whiteSpace="pre-wrap"
                    overflowWrap="anywhere"
                    pb={4}
                    pr={8}
                >
                    {tweet.text}
                </Text>
                {
                    tweet.status == "scheduled" && (
                        <VStack
                            {...cardIconStackStyle}
                        >
                            <Tooltip hasArrow label="Unschedule tweet and set it as draft">
                                <IconButton
                                    variant="action"
                                    size="sm"
                                    aria-label="unschedule tweet"
                                    icon={<Icon viewBox="0 0 16 16"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.25 5C5.8125 5 5.5 5.34375 5.5 5.75V10.25C5.5 10.6875 5.8125 11 6.25 11C6.65625 11 7 10.6875 7 10.25V5.75C7 5.34375 6.65625 5 6.25 5ZM9.75 5C9.3125 5 9 5.34375 9 5.75V10.25C9 10.6875 9.3125 11 9.75 11C10.1562 11 10.5 10.6875 10.5 10.25V5.75C10.5 5.34375 10.1562 5 9.75 5ZM8 0C3.5625 0 0 3.59375 0 8C0 12.4375 3.5625 16 8 16C12.4062 16 16 12.4375 16 8C16 3.59375 12.4062 0 8 0ZM8 14.5C4.40625 14.5 1.5 11.5938 1.5 8C1.5 4.4375 4.40625 1.5 8 1.5C11.5625 1.5 14.5 4.4375 14.5 8C14.5 11.5938 11.5625 14.5 8 14.5Z" fill="currentColor" />
                                    </svg>
                                    </Icon>}
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        await unschedule(session, tweet, tweetContext);
                                    }}
                                />
                            </Tooltip>
                            <Tooltip hasArrow label="Preview">
                                <IconButton
                                    aria-label="Preview"
                                    variant="action"
                                    style={{
                                        marginTop: '0',
                                    }}
                                    size="sm"
                                    onClick={async (e) => {
                                        openTweet(tweet);
                                    }}
                                    icon={
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8 12C8 9.8125 9.78125 8 12 8C14.1875 8 16 9.8125 16 12C16 14.2188 14.1875 16 12 16C9.78125 16 8 14.2188 8 12ZM12 14.5C13.375 14.5 14.5 13.4062 14.5 12C14.5 10.625 13.375 9.5 12 9.5C11.9688 9.5 11.9375 9.5 11.9062 9.5C11.9688 9.6875 12 9.84375 12 10C12 11.125 11.0938 12 10 12C9.8125 12 9.65625 12 9.5 11.9375C9.5 11.9688 9.5 12 9.5 12C9.5 13.4062 10.5938 14.5 12 14.5ZM5.96875 7.53125C7.4375 6.15625 9.46875 5 12 5C14.5 5 16.5312 6.15625 18 7.53125C19.4688 8.875 20.4375 10.5 20.9062 11.625C21 11.875 21 12.1562 20.9062 12.4062C20.4375 13.5 19.4688 15.125 18 16.5C16.5312 17.875 14.5 19 12 19C9.46875 19 7.4375 17.875 5.96875 16.5C4.5 15.125 3.53125 13.5 3.0625 12.4062C2.96875 12.1562 2.96875 11.875 3.0625 11.625C3.53125 10.5 4.5 8.875 5.96875 7.53125ZM12 6.5C9.9375 6.5 8.28125 7.4375 7 8.625C5.78125 9.75 4.96875 11.0625 4.53125 12C4.96875 12.9375 5.78125 14.2812 7 15.4062C8.28125 16.5938 9.9375 17.5 12 17.5C14.0312 17.5 15.6875 16.5938 16.9688 15.4062C18.1875 14.2812 19 12.9375 19.4375 12C19 11.0625 18.1875 9.75 16.9688 8.625C15.6875 7.4375 14.0312 6.5 12 6.5Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    }
                                />
                            </Tooltip>
                        </VStack>
                    )
                }
                {
                    tweet.status == "sent" && tweet.id_str && (
                        <VStack
                            {...cardIconStackStyle}
                        >
                            <IconButton
                                variant="action"
                                size="sm"
                                aria-label="Go to tweet"
                                // icon={<FiExternalLink fontSize="20px" />}
                                icon={<Icon viewBox="0 0 16 16"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.25 10C11.8125 10 11.5 10.3438 11.5 10.75V14.25C11.5 14.4062 11.375 14.5 11.25 14.5H1.75C1.59375 14.5 1.5 14.4062 1.5 14.25V4.75C1.5 4.625 1.59375 4.5 1.75 4.5H5.25C5.65625 4.5 6 4.1875 6 3.75C6 3.34375 5.65625 3 5.25 3H1.75C0.78125 3 0 3.8125 0 4.75V14.25C0 15.2188 0.78125 16 1.75 16H11.25C12.1875 16 13 15.2188 13 14.25V10.75C13 10.3438 12.6562 10 12.25 10ZM15.25 0H10C9.5625 0 9.25 0.34375 9.25 0.75C9.25 1.1875 9.5625 1.5 10 1.5H13.4375L5.71875 9.21875C5.40625 9.53125 5.40625 10 5.71875 10.2812C5.84375 10.4375 6.03125 10.5 6.25 10.5C6.4375 10.5 6.625 10.4375 6.75 10.2812L14.5 2.5625V6C14.5 6.4375 14.8125 6.75 15.25 6.75C15.6562 6.75 16 6.4375 16 6V0.75C16 0.34375 15.6562 0 15.25 0Z" fill="currentColor" />
                                </svg>
                                </Icon>}
                                as="a"
                                href={"https://x.com/" + (tweet.twUserName ?? "patxol") + "/status/" + tweet.id_str}
                                target="_blank"
                                onClick={async (e) => {
                                    e.stopPropagation();
                                }}
                            />
                            <Tooltip hasArrow label="Cancel Post-Publish Actions">
                                <IconButton
                                    variant="action"
                                    size="sm"
                                    style={{
                                        marginTop: '0',
                                    }}
                                    aria-label="Cancel post-publish actions"
                                    // icon={<MdCancelScheduleSend fontSize="20px" cursor="pointer" />}
                                    icon={
                                        <Icon viewBox="0 0 16 16"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM2.875 3.96875C2 5.09375 1.5 6.5 1.5 8C1.5 11.5938 4.40625 14.5 8 14.5C9.5 14.5 10.9062 14 12.0312 13.125L2.875 3.96875ZM14.5 8C14.5 4.4375 11.5625 1.5 8 1.5C6.46875 1.5 5.0625 2.03125 3.9375 2.90625L13.0938 12.0625C13.9688 10.9375 14.5 9.53125 14.5 8Z" fill="currentColor" />
                                        </svg>
                                        </Icon>}
                                    onClick={onOpen}
                                />
                            </Tooltip>
                            <CopyToClipboard
                                text={tweet.text}
                                onCopy={(e) => {
                                    toast.success("Content copied to clipboard");
                                }}
                            >
                                <IconButton
                                    variant="action"
                                    size="sm"
                                    style={{
                                        marginTop: '0',
                                    }}
                                    aria-label="Copy content"
                                    icon={<BiCopy fontSize="16px" cursor="pointer" />}
                                />
                            </CopyToClipboard>
                        </VStack>

                    )
                }
                {
                    tweet.status == "draft" && (
                        <VStack
                            {...cardIconStackStyle}
                        >
                            <Tooltip hasArrow label="Delete draft">
                                <IconButton
                                    variant="action"
                                    size="sm"
                                    aria-label="delete draft"
                                    icon={DeleteIcon}
                                    //   icon={<AiOutlineDelete fontSize="14px" />}
                                    onClick={async (e) => {
                                        // console.log("delete");
                                        e.stopPropagation();
                                        const db = firebaseClient.firestore();
                                        await db.collection("users").doc(getAccount(session).id).collection("tweets").doc(tweet.id).delete();
                                        if (tweetContext.id === tweet.id) {
                                            tweetContext.newTweet({ text: "" });
                                        }
                                        // getTweetsFromStatus("draft", setDrafts);
                                        removeFromList(list, tweet, setList);
                                    }}
                                />
                            </Tooltip>
                            <Tooltip hasArrow label="Preview">
                                <IconButton
                                    aria-label="Preview"
                                    variant="action"
                                    style={{
                                        marginTop: '0',
                                    }}
                                    size="sm"
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        openTweet(tweet);
                                    }}
                                    icon={
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8 12C8 9.8125 9.78125 8 12 8C14.1875 8 16 9.8125 16 12C16 14.2188 14.1875 16 12 16C9.78125 16 8 14.2188 8 12ZM12 14.5C13.375 14.5 14.5 13.4062 14.5 12C14.5 10.625 13.375 9.5 12 9.5C11.9688 9.5 11.9375 9.5 11.9062 9.5C11.9688 9.6875 12 9.84375 12 10C12 11.125 11.0938 12 10 12C9.8125 12 9.65625 12 9.5 11.9375C9.5 11.9688 9.5 12 9.5 12C9.5 13.4062 10.5938 14.5 12 14.5ZM5.96875 7.53125C7.4375 6.15625 9.46875 5 12 5C14.5 5 16.5312 6.15625 18 7.53125C19.4688 8.875 20.4375 10.5 20.9062 11.625C21 11.875 21 12.1562 20.9062 12.4062C20.4375 13.5 19.4688 15.125 18 16.5C16.5312 17.875 14.5 19 12 19C9.46875 19 7.4375 17.875 5.96875 16.5C4.5 15.125 3.53125 13.5 3.0625 12.4062C2.96875 12.1562 2.96875 11.875 3.0625 11.625C3.53125 10.5 4.5 8.875 5.96875 7.53125ZM12 6.5C9.9375 6.5 8.28125 7.4375 7 8.625C5.78125 9.75 4.96875 11.0625 4.53125 12C4.96875 12.9375 5.78125 14.2812 7 15.4062C8.28125 16.5938 9.9375 17.5 12 17.5C14.0312 17.5 15.6875 16.5938 16.9688 15.4062C18.1875 14.2812 19 12.9375 19.4375 12C19 11.0625 18.1875 9.75 16.9688 8.625C15.6875 7.4375 14.0312 6.5 12 6.5Z"
                                                fill="currentColor"
                                            />
                                        </svg>
                                    }
                                />
                            </Tooltip>
                        </VStack>
                    )
                }
                {
                    tweet.isError && (
                        <Tooltip hasArrow label={"An error occured: " + tweet.message}>
                            <IconButton
                                variant="action"
                                size="sm"
                                aria-label="delete draft"
                                icon={<BiErrorAlt color="red" fontSize="18px" />}
                                position="absolute"
                                right="5px"
                                top="5px"
                                zIndex="10"
                                cursor="pointer"
                                onClick={async (e) => {
                                    e.stopPropagation();
                                }}
                            />
                        </Tooltip>
                    )
                }
                <Box
                    position="absolute"
                    right="10px"
                    bottom="15px"
                    // bg="red"
                    w={6}
                    h={6}
                    display="flex"
                    justifyContent="flex-end"
                >
                    {
                        tweet.files && tweet.files.map((x, index) => (
                            <Image
                                key={index}
                                src={x}
                                mr={2}
                                borderRadius="5px"
                            />
                        ))
                    }
                </Box>
            </Button>
            {
                tweet.scheduledTime && ["sent", "scheduled"].includes(tweet.status) ? (
                    <Text
                        textAlign="right"
                        fontSize="12px"
                        color="gray.500"
                    >
                        {
                            //@ts-ignore
                            (tweet.status == "scheduled" ? "scheduled on " : "sent on ") + moment(tweet.scheduledTime.toDate()).tz(getAccount(session)?.timezone)?.format('YYYY-MM-DD hh:mm A')
                            // "scheduled on " + dateFormat(moment(tweet.scheduledTime.toDate()).tz(session?.user?.data?.timezone), 'mmmm dS, yyyy, h:MM TT')
                        }
                    </Text>
                ) : (
                    <>
                    </>
                )
            }
            <AlertDialog
                // @ts-ignore
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>Can you confirm?</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        This will cancel all post-publish actions like auto-retweets, auto-DM, auto-plug.
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button
                            // @ts-ignore
                            ref={cancelRef}
                            onClick={onClose}
                            variant="tertiary"
                        >
                            Cancel
                        </Button>
                        <Button
                            // colorScheme='red'
                            variant="secondaryDestructive"
                            isLoading={isConfirming}
                            // ml={3}
                            onClick={async () => {
                                setIsConfirming(true);
                                await cancelPostPublishActions(tweet);
                                setIsConfirming(false);
                                onClose();
                            }}
                        >
                            Confirm
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </Box>
    )
}