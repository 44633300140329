import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  GridItem,
  Icon,
  Image,
  SimpleGrid,
} from '@chakra-ui/react';
import {
  useMasonry,
  usePositioner,
  useContainerPosition,
  useResizeObserver,
  useScroller
} from "masonic";
import React, { Ref, useEffect, useCallback } from 'react';
import { MdRemove, MdAdd, MdOutlineEdit, MdOutlinePlayCircle, MdOutlineDescription, MdOutlineInsertDriveFile } from "react-icons/md";
import { filterUniqueByKey } from 'utils/arrayUtils';
import { colors } from "theme/colors/colors";
import { AiOutlineDelete } from 'react-icons/ai';
import { useWindowSize } from "@react-hook/window-size";
import toast from 'react-hot-toast';

export type MediaObject = {
  createdAt: {
    _seconds: number;
    _nanoseconds: number;
  };
  deleted: boolean;
  fileName: string;
  size: number;
  id: string;
  type: string;
  userId: string;
  url: string;
  updatedAt: {
    _seconds: number;
    _nanoseconds: number;
  };
};

interface MediaMasonryProps {
  medias: MediaObject[];
  tweetContext: any;
  handleDelete?: (id: string) => Promise<void>;
  openDeleteModal?: (id: string) => void;
  openEditModal?: (id: string) => void;
  isDeleting?: boolean;
  colorMode: string;
  modalMode?: boolean;
  setSelectedMedias?: (medias: string[]) => void;
  selectedMedias?: string[];
  ref?: Ref<any>;
  isSchedulerOpen?: boolean;
}

const MediaMasonry = ({ medias, tweetContext, handleDelete, openDeleteModal, openEditModal, colorMode, modalMode, setSelectedMedias, isSchedulerOpen, selectedMedias, ref: extraRef, isDeleting }: MediaMasonryProps) => {
  const getUniqMedias = () => {
    const validMedias = medias.filter(media => {
      const isValid = media && 
        media.url && 
        media.type && 
        !media.deleted && 
        typeof media.url === 'string' &&
        typeof media.type === 'string';
      
      if (!isValid) {
      }
      return isValid;
    });

    const uniqueMedias = filterUniqueByKey(validMedias, "url").filter(media => !media.deleted);
    
    return uniqueMedias;
  }
  
  const [selectedList, setSelectedList] = React.useState<string[]>([]);
  const [windowWidth, height] = useWindowSize();
  const [cleanedList, setCleanedList] = React.useState<MediaObject[]>([]);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = React.useState(height);

  const { offset, width } = useContainerPosition(containerRef, [
    windowWidth,
    containerHeight,
  ]);

  useEffect(() => {
    const updateHeight = () => {
      const element = containerRef.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const columns = Math.floor(width / 250);
        const rows = Math.ceil(cleanedList.length / columns);
        const contentHeight = rows * 250;
        setContainerHeight(contentHeight);
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [windowWidth, height, cleanedList.length, width]);

  const positioner = usePositioner(
    { 
      width: width, 
      columnGutter: 16,
      columnWidth: 250,
    }, 
    [cleanedList]
  );

  const { scrollTop, isScrolling } = useScroller(offset);
  const resizeObserver = useResizeObserver(positioner);
  const memoizedPositioner = React.useMemo(() => positioner, [positioner]);

  const memoizedRenderGridItem = useCallback(({ data: item }) => {
    return renderGridItem({ data: item });
  }, [
    selectedList,
    modalMode,
    colorMode,
    tweetContext
  ]);

  useEffect(() => {
    const cleaned = getUniqMedias();
    setCleanedList(cleaned);
  }, [medias])

  useEffect(() => {
    if (selectedMedias?.length && selectedMedias !== selectedList) {
      setSelectedList(selectedMedias);
    }
  }, [selectedMedias])

  const handleSelect = (id) => {
    if (selectedList.includes(id)) {
      const newList = selectedList.filter(x => x !== id);
      setSelectedList(newList);
      setSelectedMedias && setSelectedMedias(newList);
    } else {
      const newList = [...selectedList, id];
      setSelectedList(newList);
      setSelectedMedias && setSelectedMedias(newList);
    }
  }
  const handleUse = async (id) => {
    if (modalMode) {
      handleSelect(id);
      return;
    }
    handleSelectionValidation([id]);
  }
  const handleSelectionValidation = (files: string[]) => {
    if (files.length === 0) {
      toast.error("Please select at least one media");
      return;
    }
    tweetContext.setFiles(files);
    if (tweetContext?.refComposer) {
      tweetContext.refComposer.current?.editText("[img:" + files[0] + "]");
    }
    if (!tweetContext.isOpen) {
      tweetContext.setIsOpen(true);
    }
  }

  useEffect(() => {
    return () => {
      // @ts-ignore
      containerRef.current?.destroy && containerRef.current?.destroy();
    }
  }, [])

  const mediaIsSelected = (id) => {
    const isSelected = selectedList.includes(id) || (!modalMode && tweetContext.getFiles && tweetContext.getFiles().some(file => file === id));
    return isSelected;
  }

  const renderMediaContent = (item) => {
    const mediaType = item.type.split("/")[0];
    
    switch(mediaType) {
      case 'image':
        return (
          <Image
            src={item.thumbnail ?? item.url}
            borderBottomRadius={"none"}
            borderTopRadius={"lg"}
            objectFit="cover"
            height="200px"
            width="100%"
            onError={(e) => {
              e.currentTarget.style.display = 'none';
            }}
          />
        );
      
      case 'video':
        return (
          <Box 
            position="relative" 
            height="200px"
            width="100%"
            bg="gray.100"
            _dark={{ bg: "gray.700" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon 
              as={MdOutlinePlayCircle} 
              fontSize="4xl" 
              color="gray.500"
              _dark={{ color: "gray.400" }}
            />
          </Box>
        );
      
      case 'application':
        return (
          <Box 
            position="relative" 
            height="200px"
            width="100%"
            bg="gray.100"
            _dark={{ bg: "gray.700" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon 
              as={MdOutlineDescription} 
              fontSize="4xl" 
              color="gray.500"
              _dark={{ color: "gray.400" }}
            />
          </Box>
        );
      
      default:
        return (
          <Box 
            position="relative" 
            height="200px"
            width="100%"
            bg="gray.100"
            _dark={{ bg: "gray.700" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon 
              as={MdOutlineInsertDriveFile} 
              fontSize="4xl" 
              color="gray.500"
              _dark={{ color: "gray.400" }}
            />
          </Box>
        );
    }
  };

  const renderGridItem = ({ data: item }) => {
    console.log('Rendering item:', item.id, 'type:', item.type, 'url:', item.url);
    const mediaType = item.type.split("/")[0];
    
    return (
      <>
        {!item.deleted && (
          <Card
            maxW='xs'
            borderRadius={"xl"}
            _hover={{ boxShadow: "lg", ...modalMode && { cursor: "pointer" } }}
            onClick={() => modalMode && handleSelect(item.id)}
            border={mediaIsSelected(item.id) && `2px solid ${colors.success.lightMode[400]}`}
          >
            <CardBody p={0}>
              {renderMediaContent(item)}
              <Box 
                px={2} 
                py={1} 
                position={"absolute"} 
                backgroundColor={"#1e1e1e"} 
                opacity={0.7} 
                top={3} 
                right={3} 
                color={"#FFFFFF"} 
                fontSize={"xs"} 
                borderRadius={"lg"} 
                fontWeight={600}
              >
                {mediaType}
              </Box>
            </CardBody>
            <Divider />
            <CardFooter
              borderBottomRadius={"lg"}
              p={2}
              _dark={{
                background: "#1E1E1E"
              }}
            >
              <SimpleGrid columns={2} gap="2" w="100%" >
                <GridItem
                  colSpan={mediaType === "image" ? 2 : 1}
                  as={Button}
                  size="sm"
                  w="full"
                  fontSize="xs"
                  variant={"secondary"}
                  leftIcon={modalMode && selectedList.includes(item.id) ? <Icon as={MdRemove} fontSize="sm" /> : <Icon as={MdAdd} fontSize="sm" />}
                  onClick={async () => {
                    await handleUse(item.id)
                  }}
                >
                  {modalMode && selectedList.includes(item.id) ? "Remove from selection" : modalMode ? "Select" : "Add to post"}
                </GridItem>
                {(mediaType === "image" && openEditModal) && <Button
                  variant={"secondary"}
                  w="full"
                  size="sm"
                  fontSize="xs"
                  leftIcon={<Icon as={MdOutlineEdit} fontSize="sm" />}
                  onClick={async () => {
                    openEditModal && openEditModal(item.id)
                  }}
                >
                  {"Edit"}
                </Button>}
                {handleDelete && <Button
                  variant={"secondary"}
                  w="full"
                  size="sm"
                  fontSize="xs"
                  _dark={{
                    color: "text.darkMode.standard",
                    bg: "#1E1E1E",
                    borderColor: "border.darkMode.light",
                    _hover: {
                      color: colors.alert.darkMode.default,
                      borderColor: colors.alert.darkMode.default,
                    }
                  }}
                  sx={{
                    _hover: {
                      "svg ": {
                        color: colorMode === "dark" ? colors.alert.lightMode.default : colors.alert.darkMode.default
                      },
                      color: colorMode === "dark" ? colors.alert.lightMode.default : colors.alert.darkMode.default,
                      borderColor: colorMode === "dark" ? colors.alert.lightMode.default : colors.alert.darkMode.default,
                    }
                  }}
                  leftIcon={<Icon as={AiOutlineDelete} fontSize="sm" />}
                  onClick={async () => {
                    openDeleteModal && openDeleteModal(item.id)
                  }}
                >
                  {"Delete"}
                </Button>}
              </SimpleGrid>
            </CardFooter>
          </Card>
        )}
      </>
    );
  };

  return (
    <Box ref={containerRef}>
      {
        useMasonry({
          resizeObserver,
          positioner: memoizedPositioner,
          scrollTop,
          isScrolling,
          containerRef,
          items: cleanedList,
          height: containerHeight,
          overscanBy: 2,
          render: memoizedRenderGridItem,
        })
      }
    </Box>
  );
}

export default MediaMasonry;