import { MutableRefObject, FC, useRef } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useColorMode } from "@chakra-ui/react";
import classNames from "classnames";
import shortid from "shortid";
import { uploadOnS3 } from "utils/uploadUtils";
import { postMedia, createThumbnail } from "controllers/media";
import toast from "react-hot-toast";
import { ProcessServerConfigFunction } from "filepond";
import { Session } from 'next-auth';

const BUCKET_NAME = process.env.NEXT_PUBLIC_PROJECT_BUCKET;

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const filePondLabel = `
    <div class="filepond--icon">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 13C21.7348 13 21.4804 13.1054 21.2929 13.2929C21.1054 13.4804 21 13.7348 21 14V18.213C20.9992 18.9519 20.7053 19.6603 20.1828 20.1828C19.6603 20.7053 18.9519 20.9992 18.213 21H5.787C5.04809 20.9992 4.33966 20.7053 3.81717 20.1828C3.29468 19.6603 3.00079 18.9519 3 18.213V14C3 13.7348 2.89464 13.4804 2.70711 13.2929C2.51957 13.1054 2.26522 13 2 13C1.73478 13 1.48043 13.1054 1.29289 13.2929C1.10536 13.4804 1 13.7348 1 14V18.213C1.00132 19.4822 1.50609 20.699 2.40354 21.5965C3.30099 22.4939 4.51782 22.9987 5.787 23H18.213C19.4822 22.9987 20.699 22.4939 21.5965 21.5965C22.4939 20.699 22.9987 19.4822 23 18.213V14C23 13.7348 22.8946 13.4804 22.7071 13.2929C22.5196 13.1054 22.2652 13 22 13Z" fill="#828282"/>
        <path d="M6.707 8.70703L11 4.41403V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8947 11.7348 18 12 18C12.2652 18 12.5196 17.8947 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V4.41403L17.293 8.70703C17.4816 8.88919 17.7342 8.98998 17.9964 8.9877C18.2586 8.98543 18.5094 8.88026 18.6948 8.69485C18.8802 8.50944 18.9854 8.25863 18.9877 7.99643C18.99 7.73423 18.8892 7.48163 18.707 7.29303L12.707 1.29303C12.5195 1.10556 12.2652 1.00024 12 1.00024C11.7348 1.00024 11.4805 1.10556 11.293 1.29303L5.293 7.29303C5.11085 7.48163 5.01005 7.73423 5.01233 7.99643C5.01461 8.25863 5.11978 8.50944 5.30518 8.69485C5.49059 8.88026 5.7414 8.98543 6.0036 8.9877C6.2658 8.98998 6.5184 8.88919 6.707 8.70703Z" fill="#828282"/>
        </svg>
    </div>
    <div>Click to <span class="filepond--label-action">Upload</span> or drag & drop</div>
`;

interface FileUploaderProps {
  session: Session;
  mode?: string;
  isSavingImage?: boolean;
  setIsSavingImage?: (value: boolean) => void;
  isSavingGif?: boolean;
  setIsSavingGif?: (value: boolean) => void;
  isFileValid: (file: File) => Promise<boolean>;
  addFileOnThread: (fileName: string) => void;
  onCloseImageUpload: () => void;
  refData: MutableRefObject<{ files?: string[] } | undefined>;
}

const uploadingFiles: string[] = [];

export const FileUploader: FC<FileUploaderProps> = ({
  session,
  mode = "tweet",
  setIsSavingImage,
  setIsSavingGif,
  isFileValid,
  addFileOnThread,
  onCloseImageUpload,
  refData,
}) => {
  const { colorMode } = useColorMode();
  const refPond = useRef<FilePond>(null);

  const handleBeforeAddFile = async (item: any) => {
    if (uploadingFiles.includes(item.file.name)) {
      console.error("already uploading this image");
      return false;
    }

    if (!(await isFileValid(item.file))) {
      return false;
    }

    uploadingFiles.push(item.file.name);
    return true;
  };

  const handleOnUpdateFiles = (filesUpdated: any[]) => {
    filesUpdated.forEach((x) => {
      if (!x.getMetadata("fileName")) {
        let id = "";
        if (x.file.type.includes("video")) {
          id += "vid-";
        }

        if (x.file.type.includes("gif")) {
          id += "gif-";
        }

        id += shortid.generate();

        let fileName = id;
        let link =
          "https://ez4cast.s3.eu-west-1.amazonaws.com/userUpload/" + fileName;

        x.setMetadata("id", id);
        x.setMetadata("fileName", fileName);
        x.setMetadata("link", link);
      }
    });

    let processFiles = filesUpdated.map((x) => x.getMetadata("link"));
  };

  const handleProcessFile = (error: any, file: any) => {
    if (uploadingFiles.includes(file.file.name))
      uploadingFiles.splice(uploadingFiles.indexOf(file.file.name), 1);

    if (error) {
      toast.error("An error occurred while uploading your content");
    } else {
      let newLink = file.getMetadata("link");
      let fileName = file.getMetadata("fileName");

      if (refData.current) {
        refData.current.files = [...(refData.current.files || []), newLink];
      }

      addFileOnThread(fileName);
      onCloseImageUpload();
    }
  };

  const handleFilePondServerProcess = async (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options
  ) => {
    if (file.type !== "image/gif") {
      setIsSavingImage?.(true);
    }

    let fileName = metadata.fileName;

    try {
      const params = {
        Bucket: BUCKET_NAME,
        Key: "userUpload/" + fileName,
        Body: file,
        ACL: "public-read",
      };

      try {
        var url = await uploadOnS3(params);

        load(url);
        await postMedia(session, {
          url: url,
          type: file.type,
          fileName: fileName,
          size: file.size,
          id: metadata.id,
        });
        if (file.type.includes("video")) {
          await createThumbnail(session, { id: metadata.id });
        }
        setIsSavingImage?.(false);
        setIsSavingGif?.(false);
      } catch (e) {
        console.error(e);
        setIsSavingImage?.(false);
        setIsSavingGif?.(false);
        throw e;
      }
    } catch (e) {
      console.error(e);
      console.log("Error in upload: " + e, JSON.stringify(e));
    }
    return {
      abort: () => {
        setIsSavingImage?.(false);
        setIsSavingGif?.(false);
      },
    };
  };

  return (
      <FilePond
        ref={refPond}
        dropOnElement={false}
        dropValidation={mode === "tweet"}
        allowPaste={true}
        allowImagePreview={false}
        beforeAddFile={handleBeforeAddFile}
        onupdatefiles={handleOnUpdateFiles}
        onprocessfile={handleProcessFile}
        allowMultiple={true}
        maxFiles={999}
        name="files"
        labelIdle={filePondLabel}
        className={classNames("upload-image-filepond", {
          "filepond--panel-dark": colorMode === "dark",
          "filepond--panel-light": colorMode === "light",
        })}
        server={{
          process: handleFilePondServerProcess as unknown as ProcessServerConfigFunction,
        }}
      />
  );
};
