// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: true,
  dsn: SENTRY_DSN || 'https://91121e02ec9e4f3192a957f79409ea1e@o663088.ingest.us.sentry.io/5765735',
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // tracesSampleRate: 0.2,
  tracesSampleRate: 0.1,
  integrations: [
    new Sentry.Integrations.BrowserTracing({
      tracingOrigins: ["api.tweetbutler.com"],
    }),
  ],
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'TypeError: Cancelled',
    /Network Error/,
    "Rewardful is not defined",
  ],
});