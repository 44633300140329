import { firebaseClient } from "firebaseClient";
import toast from "react-hot-toast";
import { signOut } from "next-auth/client";
import * as Sentry from "@sentry/nextjs";
import { setInLocalStorage } from "../utils/helpers";
import { getRandomInList } from "../utils/tweetUtils";
import { Session } from "next-auth";

let accountFields = [
  "description",
  "bio",
  "bioFormatted",
  "who",
  "topics",
  "keywords",
  "tempLevel",
  "thWriteAccessToken",
  "thWriteSecretToken",
  "thApp",
  "twUserName",
  "provider",
  "name",
  "image",
  "needInit",
  "engageAccounts",
  "engagementDistribution",
  "schedule",
  "isAutoRetweet",
  "isAutoPlug",
  "autoRetweetHours",
  "autoRetweetTimes",
  "autoPlugText",
  "autoPlugTrigger",
  "autoPlugList",
  "autoDmText",
  "autoDmTrigger",
  "isAutoDeleteRt",
  "autoDeleteRtHours",
  "preventLinkExpand",
  "preventLinkExpandAutoPlug",
  "isPublicStats",
  "accountForPrediction",
  "autoAddEvergreenTrigger",
  "fanListToNotify",
  "isPushTaplio",
  "lastFetchStatsDate",
  "idParent",
  "idAccount",
  "dateAdded",
  "followers_count",
  "favourites_count",
  "friends_count",
  "statuses_count",
  "isWatchThreads",
  "addSecondaryCta",
  "isSubscriberWebhookActive",
  "ctaButtonText",
  "ctaButtonLink",
  "threadSubscriberWebhooks",
  "suggestions",
  "dynamicBanner",
  "autoDelete",
  "repostAutoDelete",
  "autoDMPinned",
  "fineTunedModel",
  "isDMsPaused",
];

let forceProd = true;
let baseAuthUrl =
  (!process.env.NODE_ENV || process.env.NODE_ENV === "development") &&
  !forceProd
    ? "http://localhost:3001"
    : "https://auth.tweethunter.io";

export const twitterApps = [
  {
    thApp: "T_TWITTERGROWTHCONTEST",
    url: "https://auth4.tweethunter.io",
  },
  {
    thApp: "T_LEM_AUTH50",
    url: "https://auth50.tweethunter.io",
  },
  {
    thApp: "T_LEM_AUTH51",
    url: "https://auth51.tweethunter.io",
  },
  {
    thApp: "T_LEM_AUTH52",
    url: "https://auth52.tweethunter.io",
  },
];

function getMainAccount(session) {
  if (session?.user?.data?.accounts?.length) {
    let match = session.user.data.accounts.find(
      (x) => x.id === session?.user?.uid,
    );
    if (match) {
      let account = session.user.data as any;

      for (const [key, value] of Object.entries(match)) {
        if (
          accountFields.includes(key) &&
          value !== undefined &&
          value !== null
        ) {
          account[key] = value;
        }
      }

      account.idAccount = session?.user?.uid;
      account.idParent = session?.user?.uid;
      session.user.selectedAccount = account;
      return account;
    }
  }

  return session.user.data;
}

function getOwnedAccount(session, selectedAccountId) {
  if (session?.user?.data?.accounts) {
    let match = session.user.data.accounts.find(
      (x) => x.id === selectedAccountId,
    );
    if (match) {
      session.user.selectedAccountId = selectedAccountId;

      let dataFromMainAccount = {} as any;
      for (const [key, value] of Object.entries(session.user.data)) {
        if (!accountFields.includes(key)) {
          dataFromMainAccount[key] = value;
        }
      }

      let selectedAccount = {
        ...match,
        ...dataFromMainAccount,
        idAccount: match.idAccount ?? session?.user?.data?.id,
        type: "owned",
      };
      console.log("getOwnedAccount", { selectedAccount });
      session.user.selectedAccount = selectedAccount;
      return selectedAccount;
    }
  }

  return null;
}

function getSharedAccount(session, selectedAccountId) {
  if (session?.user?.linkAccounts) {
    let match = session.user.linkAccounts.find(
      (x) => x.id === selectedAccountId,
    );
    if (match) {
      session.user.selectedAccount = match;
      session.user.selectedAccountId = selectedAccountId;
      return match;
    } else {
      let selectedAccount: any = localStorage.getItem("selectedAccount");
      if (selectedAccount) {
        selectedAccount = JSON.parse(selectedAccount);
        if (selectedAccount) {
          session.user.selectedAccount = selectedAccount;
          session.user.selectedAccountId = selectedAccount.id;
          return selectedAccount;
        }
      }
    }
  }

  return null;
}

export const getAuthUrl = (session) => {
  return baseAuthUrl + "/signth?masterUserId=" + session?.user?.uid;
};

export function pickRandomTwitterApp() {
  return getRandomInList(twitterApps, 1)[0];
}

export const stopImpersonating = async (session) => {
  console.log(session?.user?.data?.impersonatingOriginUser);
  let response = await fetch(
    "/api/impersonate/stop?id=" +
      session?.user?.data?.impersonatingOriginUser?.id,
  );
  let data = await response.json();
  if (data?.success) location.reload();
  else toast.error("Error while stopping impersonation");
};

export const selectAccount = (session, acc, type) => {
  console.log(
    "selectAccount " +
      acc.id +
      " / uid: " +
      session?.user?.uid +
      " / type: " +
      type,
  );
  console.log("selectAccount", session);
  if (session?.user?.uid) {
    session.user.selectedAccountType = type;
    session.user.selectedAccountId = acc.id;
    session.user.selectedAccount = null;
    setInLocalStorage("selectedAccountId", acc.id);
    setInLocalStorage("selectedAccountType", type);
    localStorage.removeItem("selectedAccount");
    getAccount(session);
  }
};

export const deleteCredentialsIfExpired = async (session, code) => {
  if (code && [89, 401, 215].includes(code)) {
    updateUser(session, {
      thWriteAccessToken: "",
      thWriteSecretToken: "",
      thApp: "",
    });
  }
};

export const hasEditRight = (session) => {
  if (getAccount(session)?.shareRole == "editor") {
    toast.error("You don't have the authorization to do this.");
    return false;
  }

  return true;
};

export const updateUser = async (
  session,
  data,
  showToast = false,
  updateTweetButlerDB = false,
  conf = {} as any,
) => {
  const db = firebaseClient.firestore();
  let account = getAccount(session);
  let trueAccount = account;

  if (!hasEditRight(session)) return;

  if (account?.type === "owned") {
    let match = getAccount(session)?.accounts?.find(
      (x) => x.id == getAccount(session).idAccount,
    );
    if (match) {
      trueAccount = match;
    }
  }

  let toSaveGlobal: any = {};
  let toSaveLocal: any = {};
  for (const [key, value] of Object.entries(data)) {
    if (accountFields.includes(key)) {
      if (!conf.disableUpdateUser) {
        account[key] = value;
        trueAccount[key] = value;
      }
      toSaveLocal[key] = value;
    } else {
      if (!conf.disableUpdateUser) session.user.data[key] = value;
      toSaveGlobal[key] = value;
    }

    if (!conf.disableUpdateUser && session?.user?.selectedAccount)
      session.user.selectedAccount[key] = value;
  }

  if (account?.type == "owned") {
    console.log("update account");

    if (Object.keys(toSaveLocal)?.length > 0)
      await db
        .collection("users")
        .doc(session?.user?.uid)
        .collection("accounts")
        .doc(account.idAccount)
        .update(toSaveLocal);

    if (Object.keys(toSaveGlobal)?.length > 0)
      await db.collection("users").doc(session?.user?.uid).update(toSaveGlobal);
  } else {
    await db.collection("users").doc(getAccount(session).id).update(data);

    if (Object.keys(toSaveLocal)?.length > 0) {
      let matchMainAccount = getAccount(session)?.accounts?.find(
        (x) => x.id == getAccount(session).id,
      );
      const hasFullAcccess = getAccount(session)?.shareRole === "full access";
      if (matchMainAccount || hasFullAcccess) {
        await db
          .collection("users")
          .doc(getAccount(session).id)
          .collection("accounts")
          .doc(getAccount(session).id)
          .update(toSaveLocal);
      }
    }
  }

  if (showToast) {
    toast.success("Data updated", {
      style: { background: "gray.600", color: "#222" },
      duration: 10000,
    });
  }
};

export const logout = async (isRedirect = true) => {
  try {
    localStorage.clear();
  } catch (e) {
    console.log("localStorage error", e);
    Sentry.captureException(e);
  }
  try {
    await deleteAllCookies();
  } catch (e) {
    console.log("deleteAllCookies error", e);
    Sentry.captureException(e);
  }
  if (isRedirect) signOut({ callbackUrl: "https://tweethunter.io" });
  else signOut({ redirect: false });
};

export const deleteAllCookies = async function () {
  console.log("deleteAllCookies");
  await fetch("/api/auth/logout");
};

export const getAccountId = (session) => {
  let selectedAccountId: any = session?.user?.selectedAccountId;

  if (!selectedAccountId)
    selectedAccountId = localStorage.getItem("selectedAccountId");

  if (!selectedAccountId) selectedAccountId = session.user.uid;

  return selectedAccountId;
};

/**
 * Retrieves the active account from the given session
 * 
 * This function determines which account should be used 
 * based on the session data. It checks for a selected 
 * account in multiple places, including the session object 
 * and local storage, and then retrieves the correct account 
 * based on its type
 * 
 * @param {Session} session - The user session object
 * 
 * @returns {any | null} The selected account object if found, otherwise null
 */
export const getAccount = (session) => {
  if (!session?.user?.uid) return null;

  let selectedAccountId = session?.user?.selectedAccountId;

  if (!selectedAccountId && typeof window != "undefined")
    selectedAccountId = localStorage.getItem("selectedAccountId");

  if (session?.user?.selectedAccount) {
    return session.user.selectedAccount;
  } else if (selectedAccountId) {
    let type =
      session?.user?.selectedAccountType ||
      (typeof window != "undefined" &&
        localStorage.getItem("selectedAccountType"));

    if (type == "shared") {
      let account = getSharedAccount(session, selectedAccountId);
      if (account) return account;
    } else if (type == "owned") {
      let account = getOwnedAccount(session, selectedAccountId);
      if (account) return account;
    }
  }

  if (session?.user?.data) {
    let account = getMainAccount(session);
    return account;
  }

  return null;
};

/**
 * Checks if the given session's account is premium
 * 
 * For shared accounts, it checks the isPremium flag directly on the shared account object.
 * 
 * @param {Session} session - The user session object
 * @returns {{ isPremium: boolean }} 
 * True if the account is premium, otherwise false
 * 
 */
export const getAccountIsPremium = (session: Session): { isPremium: boolean } => {
  const account = getAccount(session); 
  
  if (account?.type === "shared") {
    return { isPremium: account.isPremium ?? false };
  }

  if (!account || !Array.isArray(session?.user?.data?.accounts)) {
    return { isPremium: false };
  }
  
  const matchingAccount = session.user.data.accounts.find(
    (acc) => acc.idAccount === account.idAccount
  );

  return { isPremium: matchingAccount?.isPremium ?? false };
};
