export const TWEET_BUTLER_API_BASE_URL = "https://api.tweetbutler.com";

export const ez4castBaseUrls = {
  devEurope: "http://127.0.0.1:5001/ez4cast/europe-west1/",
  devUS: "http://127.0.0.1:5001/ez4cast/us-central1/",
  prod: "https://us-central1-ez4cast.cloudfunctions.net/",
};

export const ez4castEndpoints = {
  mentions: "tweetChampions-mentions",
  mostRecentTweets: "tweetMostRecents-getMostRecentTweets",
  fetchTweet: "twitterFetcher-getTweet",
};

export const ez4castApiUrls = {
  mentions: {
    dev: `${ez4castBaseUrls.devUS}${ez4castEndpoints.mentions}`,
    prod: `${ez4castBaseUrls.prod}${ez4castEndpoints.mentions}`,
  },
  mostRecentTweets: {
    dev: `${ez4castBaseUrls.devUS}${ez4castEndpoints.mostRecentTweets}`,
    prod: `${ez4castBaseUrls.prod}${ez4castEndpoints.mostRecentTweets}`,
  },
  fetchTweet: {
    dev: `${ez4castBaseUrls.devUS}${ez4castEndpoints.fetchTweet}`,
    prod: `${ez4castBaseUrls.prod}${ez4castEndpoints.fetchTweet}`,
  },
};
